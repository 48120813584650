import * as React from 'react'
import Urls from '../utils/Urls'
import { Button, Modal, ModalHeader, ModalBody, Spinner, FormGroup, Col, Row, Label, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFileExport } from '@fortawesome/free-solid-svg-icons'
var DatePicker = require("reactstrap-date-picker");

type Dictionary = { [index: string]: any }
interface Props { };
interface State {
    startDate: Date
    endDate: Date
    exportModal: boolean
    errandsToExport: Dictionary
    loading: boolean
    dataFetched: boolean
};

export default class ExportPage extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props)

        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            exportModal: false,
            errandsToExport: [],
            loading: false,
            dataFetched: false
        }
    }


    componentDidMount() {
    }

    private exportErrands = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        let offices: Array<string> = Object.keys(this.state.errandsToExport);
        csvContent +=  "Fastighetsägare;Antal\r\n";
        offices.forEach((key) => {
            let row = `${key};${this.state.errandsToExport[key].length}`;
            csvContent += row + "\r\n";
        })
        let encodedUri = encodeURI(csvContent);

        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Ärenden_" + new Date().toISOString() + ".csv");
        document.body.appendChild(link); // Required for FF
        link.click();
        this.setState({
            exportModal: false
        })
    }

    private startDateOnChange = (iso: string, formatted: string) => {
        this.setState({
            startDate: new Date(iso)
        });
    }

    private endDateOnChange = (iso: string, formatted: string) => {
        this.setState({
            endDate: new Date(iso)
        });
    }

    private fetchData = () => {
        this.setState({
            loading: true
        })
        let endDate = document.getElementById("endDate") as HTMLInputElement;
        let startDate = document.getElementById("startDate") as HTMLInputElement;
        let data = {
            from: startDate.value,
            to: endDate.value
        }
        fetch(Urls.GetErrandsWithinInterval, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.status == 200) {
                    return res.json();
                }
            })
            .then(errands => {
                this.setState({
                    loading: false,
                    dataFetched: true,
                    errandsToExport: errands
                })
            })
            .catch(e => {
                console.log(`Problem fetching errands: ${e}`);
            })
    }

    render() {
        let offices = Object.keys(this.state.errandsToExport);
        return (
            <div style={{ maxWidth: "700px", margin: "auto" }}>
                <h4>Exportera antal ärenden</h4>
                <FormGroup>
                    <Row>
                        <Col xs={4}>
                            <Label>
                                Från och med
                            </Label>
                            <DatePicker id="startDate" dateFormat="DD-MM-YYYY" onChange={this.startDateOnChange} value={this.state.startDate.toISOString()} />
                        </Col>
                        <Col xs={4}>
                            <Label>
                                Till och med
                            </Label>
                            <DatePicker id="endDate" dateFormat="DD-MM-YYYY" onChange={this.endDateOnChange} value={this.state.endDate.toISOString()} />
                        </Col>
                        <Col xs={4} style={{alignSelf: "flex-end"}}>
                            <Button color="primary" className="width-100" onClick={this.fetchData}>
                                <FontAwesomeIcon icon={faDownload} />
                                <span style={{ marginLeft: "7px" }}>Hämta</span>
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>
                {
                    this.state.loading &&
                    <Spinner color="primary" />
                }
                {
                    !this.state.loading && this.state.dataFetched &&
                    <div>
                    <FormGroup>
                        <Row>
                            <Col xs={6}>
                                <h5>Data som kommer exporteras</h5>
                            </Col>
                            <Col xs={2}>
                            </Col>
                            <Col xs={4}>
                                <Button color="primary" className="width-100" onClick={this.exportErrands}>
                                    <FontAwesomeIcon icon={faFileExport} />
                                    <span style={{ marginLeft: "7px" }}>Exportera</span>
                                </Button>
                            </Col>
                        </Row>
                        
                    </FormGroup>
                    <FormGroup>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Fastighetsägare</th>
                                    <th>Antal ärenden</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    offices.map(office => {
                                        return (
                                            <tr>
                                                <th>{office}</th>
                                                <td>{this.state.errandsToExport[office].length}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        </FormGroup>
                        </div>
                }
                
            </div>
        )
    }
}
