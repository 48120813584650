//import React from 'react';
import * as React from 'react'
//import { FaSyncAlt } from 'react-icons/fa/index';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Updating: React.SFC<any> = (props) => {
    return <FontAwesomeIcon icon={faSyncAlt} className="asyncstatusicon-updating spin" {...props} />;
}

export default Updating;