//import React from 'react';
import * as React from 'react'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { FaTimesCircle } from 'react-icons/fa/index';
//import { OverlayTrigger } from 'react-bootstrap'

interface Props {
    errorMessage?: string
    //Any other props:
    [x:string]: any
}

const Error : React.SFC<Props> = (props) => {
    if(props.errorMessage) {

        return (
            //<OverlayTrigger
            //    key={'error'}
            //    placement={'bottom'}
            //    overlay={tooltip}
            //>
                <FontAwesomeIcon icon={faTimesCircle} className="asyncstatusicon-error shake" />
            //</OverlayTrigger >
                );
    }
    else {
        return <FontAwesomeIcon icon={faTimesCircle} className="asyncstatusicon-error shake" />;
    }
}

export default Error