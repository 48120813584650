//import React from 'react';
import * as React from 'react'
//import { FaCheckCircle } from 'react-icons/fa/index';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Success : React.SFC<any> = (props) => {
    return <FontAwesomeIcon icon={faCheckCircle} className="asyncstatusicon-success pop-in-fade-out" />;
}

export default Success;