import * as React from 'react';
//import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ReportPage from './pages/ReportPage';
import AdminSchemePage from './pages/AdminSchemePage';
import AdminUserPage from './pages/AdminUserPage';
import ErrandsPage from './pages/ErrandsPage';
import ViewErrandPage from './pages/ViewErrandPage';
import ChangePdfPage from './pages/ChangePdfPage';
import ExportPage from './pages/ExportPage';
import UserSettingsPage from './pages/UserSettingsPage';
import DocumentationPage from './pages/DocumentationPage'
import WelcomePage from './pages/WelcomePage';

export default class App extends React.Component {
  static displayName = App.name;

    render() {
    return (
        <Layout>
            <AuthorizeRoute exact path='/' component={WelcomePage} />
            <AuthorizeRoute exact path='/errands' component={ErrandsPage} />
            <AuthorizeRoute path='/errand/:office/:id' component={ViewErrandPage} />
            <AuthorizeRoute path='/report' component={ReportPage} />
            <AuthorizeRoute path='/admin-scheme' component={AdminSchemePage} />
            <AuthorizeRoute path='/admin-users' component={AdminUserPage} />
            <AuthorizeRoute path='/pdf' component={ChangePdfPage} />
            <AuthorizeRoute exact path='/history' component={() => <ErrandsPage showHistory={true} />} />
            <AuthorizeRoute path='/export' component={ExportPage} />
            <AuthorizeRoute path='/user-settings' component={UserSettingsPage} />
            <AuthorizeRoute path='/documentation' component={DocumentationPage} />
            
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
