import { title } from 'process';
import Urls from '../utils/Urls';
import MapErrandData from '../utils/ErrandInfo';
import * as React from 'react'
import { ListGroup, ListGroupItem, Collapse } from 'reactstrap';
import { InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Table } from 'reactstrap';
import { ButtonGroup, Button, ButtonToggle, Form, FormGroup, Label, Input, FormFeedback, FormText, Col, Row } from 'reactstrap';
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    Redirect
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

type Dictionary = { [index: string]: any }
interface Props {
    errand : Dictionary
};

interface State {
    infoModal: boolean
    modalInfo: Array<Dictionary>
    routeToErrand: boolean
};

export default class ErrandListItem extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props)

        this.state = {
            infoModal: false,
            modalInfo: MapErrandData(this.props.errand),
            routeToErrand: false
        }

        this.toggleInfoModal = this.toggleInfoModal.bind(this);
        this.goToErrand = this.goToErrand.bind(this);

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps : Props, prevState : State) {
        if (prevProps.errand.id != this.props.errand.id) {
            this.setState({
                modalInfo: MapErrandData(this.props.errand)
            })
        }
    }

    private toggleInfoModal() {
        this.setState({
            infoModal: !this.state.infoModal
        });
    }

    private goToErrand() {
        this.setState({
            routeToErrand : true
        })
    }

    render() {
        return (
            
            <ListGroupItem className="padding-0">
                {
                    this.state.routeToErrand &&
                    <Redirect to={`/errand/${this.props.errand.office.name}/${this.props.errand.id}`} />
                }
                <InputGroup>
                    <Button outline onClick={this.goToErrand} color="primary" className="btn-no-border width-90 flex">
                        <span className="text-left width-10">#{this.props.errand.id}</span>
                        <span className="text-left width-30">{this.props.errand.name}</span>
                        <span className="text-left width-30">{this.props.errand.propertyDesignaton}</span>
                        <span className="width-30 text-center">{this.props.errand.status}</span>
                    </Button>
                    <Button onClick={this.toggleInfoModal} outline className="btn-no-border width-10">
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </Button>
                </InputGroup>
                <Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal}>
                    <ModalHeader toggle={this.toggleInfoModal}>Information</ModalHeader>
                    <ModalBody>
                        <Table>
                            <tbody>
                                {
                                    this.state.modalInfo.map(info => {
                                        return (
                                            <tr>
                                                <th >{info.key}</th>
                                                <td>{info.value}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </ModalBody>
                </Modal>
            </ListGroupItem>
        )
    }
}