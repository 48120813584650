type Dictionary = { [index: string]: any }

export const Roles: Dictionary = {
    "User": {
        title: "Företagsanvändare"
    },
    "Admin": {
        title: "Företagsadmin"
    },
    "GlobalAdmin": {
        title: "Global Admin"
    }
};

export const GlobalRoles = [
    "GlobalAdmin"
];

export const AdminRoles = [
    "Admin"
];

export const UserRoles = [
    "User"
];

export default Roles;

