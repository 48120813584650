import * as React from 'react'
import Urls from '../utils/Urls'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Spinner, Row, Col, Input, Label, ListGroupItem, ListGroup } from 'reactstrap'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Updating, Success, Error, ErrorMessage } from '../components/visuals/Visuals';


enum UpdateFlag {
    Idle,
    Updating,
    Success,
    Error
}

interface Props { };
interface State {
    error: string | null
    modal: boolean
    pdfs: string[];
    loading: boolean
};

export default class ChangePdfPage extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props)

        this.state = {
            error: null,
            modal: false,
            pdfs: [],
            loading: true,
        }


    }


    componentDidMount() {
        fetch(Urls.GetFiles)
            .then(res => {
                return res.json();
            })
            .then(json => {
                this.setState({ pdfs: json, loading: false })
            })
    }

    render() {
        return (
            <div style={{ maxWidth: "700px", margin: "auto" }}>
                {
                    this.state.loading &&
                    <Spinner color="primary" />
                }
                {
                    !this.state.loading &&
                    <div>
                        <h5>Dokument</h5>
                        <ListGroup flush>
                                {
                                    this.state.pdfs.map((file) =>
                                    <ListGroupItem
                                            href={Urls.GetPDF + "/" + file}
                                            action
                                            tag="a"
                                            className="documentation-list-item"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon size="lg" style={{marginRight: "4px"}} icon={faFilePdf} /> {file}
                                    </ListGroupItem>)
                            }

                        </ListGroup>

                    </div>
                }

            </div>
        )
    }
}
