import * as React from 'react'
import Urls from '../utils/Urls'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap'

type Dictionary = { [index: string]: any }
interface Props { };
interface State {
    newPasswordEntered: boolean
    error: string | null
    modal: boolean
    modalText: string
};

export default class UserSettingsPage extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props)

        this.state = {
            error: null,
            newPasswordEntered: false,
            modal: false,
            modalText: ""
        }

    }

    componentDidMount() {
    }

    private onChangePassword = () => {
        let currentPassInput = document.getElementById('currentPassword') as HTMLInputElement;
        let newPassInput = document.getElementById('newPassword') as HTMLInputElement;
        let currentPass = currentPassInput.value;
        let newPass = newPassInput.value;
        let data = {
            OldPassword: currentPass,
            NewPassword: newPass
        }
        fetch(Urls.UpdateMyPassword, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status == 200) {
                this.showModal("Lösenord ändrat");
                currentPassInput.value = "";
                newPassInput.value = "";
            }
            else if (res.status == 204) {
                this.showModal("Nuvarande lösenord stämmer inte");
            }
            else {
                this.showModal("Något gick fel");
            }
        }).catch(e => {
            console.log(`Problem when changing password: ${e}`);
            this.showModal("Något gick fel");
        });
    }

    private onInputFieldsChanged = () => {
        let currentPassInput = document.getElementById('currentPassword') as HTMLInputElement;
        let newPassInput = document.getElementById('newPassword') as HTMLInputElement;
        if (currentPassInput.value.length > 0 && newPassInput.value.length > 0) {
            this.setState({
                newPasswordEntered: true
            })
        }
        else {
            this.setState({
                newPasswordEntered: false
            })
        }
    }

    private showModal = (headerText: string) => {
        this.setState({
            modal: true,
            modalText: headerText
        })
    }

    private closeModal = () => {
        this.setState({
            modal: false
        })
    }

    render() {
        return (
            <div style={{ maxWidth: "700px", margin: "auto" }}>
                <h5>Lösenordshantering</h5>
                <FormGroup>
                    <Label>Nuvarande lösenord</Label>
                    <Input style={{ marginBottom: "0.5rem" }} type="password" id="currentPassword" onChange={this.onInputFieldsChanged}/>
                </FormGroup>
                <FormGroup>
                    <Label>Ange nytt lösenord</Label>
                    <Input style={{ marginBottom: "0.5rem" }} type="password" id="newPassword" onChange={this.onInputFieldsChanged} />
                    <div style={{marginLeft: 4, fontSize: "12px"}}>
                        <div>- Minst 6 tecken</div>
                        <div>- Både VERSALER och gemener</div>
                        <div>- Minst 1 siffra</div>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Button onClick={this.onChangePassword} disabled={!this.state.newPasswordEntered} color="primary">Byt lösenord</Button>
                </FormGroup>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader style={{ margin: "auto" }}>{this.state.modalText}</ModalHeader>
                    <ModalFooter style={{ margin: "auto" }}>
                        <Button color="primary" onClick={this.closeModal}>Ok</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
