import * as React from 'react'
import Urls from '../utils/Urls'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

type Dictionary = { [index: string]: any }
interface Props {  };
interface State {
    isElevated: boolean
    error: string | null
    officeOptions: Array<string>
    modal: boolean
};

export default class ReportPage extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props)
        
        this.state = {
            error: null,
            isElevated: false,
            officeOptions: [],
            modal: false
        }

        fetch(Urls.GetOffices + "?$select=name")
            .then(res => {
                if (res.status == 200) {
                    return res.json();
                }
            })
            .then(offices => {
                if (offices) {
                    this.setState({
                        isElevated: true,
                        officeOptions: offices.map((ofc: Dictionary) => ofc['Name']),
                    });
                }
            })
    }

    private toggle = () => this.setState({ modal: !this.state.modal });

    componentDidMount() {
    }

    private onSubmit(event : any) {
        event.preventDefault();
        event.stopPropagation();
        let form: HTMLFormElement = document.getElementsByClassName("needs-validation")[0] as HTMLFormElement;
        let validation = form.checkValidity();
        form.classList.add("was-validated");
        if (validation) {
            this.createNewReport();
        }
    }

    private resetForm() {
        let form: HTMLFormElement = document.getElementsByClassName("needs-validation")[0] as HTMLFormElement;
        form.reset();
        form.classList.remove("was-validated");
    }

    private createNewReport() {
        let officeName = (document.getElementById("inputPropOwner") as HTMLInputElement)?.value;
        let url = this.state.isElevated ? `${Urls.CreateErrandWithOffice}?officeName=${officeName}` : Urls.CreateErrand;
        let data = {
            Name: (document.getElementById("inputName") as HTMLInputElement)?.value,
            PropertyName: (document.getElementById("inputPropName") as HTMLInputElement)?.value,
            PropertyDesignaton: (document.getElementById("inputPropDesi") as HTMLInputElement)?.value,
            ContactPerson: (document.getElementById("inputContact") as HTMLInputElement)?.value,
            TenantName: (document.getElementById("inputAppliName") as HTMLInputElement)?.value,
            TenantEmail: (document.getElementById("inputEmail") as HTMLInputElement)?.value,
            TenantPhone: (document.getElementById("inputPhone") as HTMLInputElement)?.value,
            Description: (document.getElementById("inputDesc") as HTMLInputElement)?.value
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status == 200) {
                this.toggle();
                this.resetForm();
            }
        }).catch(e => {
            console.log(`Problem when creating a new report: ${e}`);
        });
    }

    private onCreated() {
        window.location.href = "/errands";
    }

    render() {
        return (
            <div style={{ maxWidth: "700px", margin: "auto" }}>
                <h5>Nytt ärende</h5>
                <form  className="needs-validation">
                    {
                        this.state.isElevated && 
                        <div className="form-group row">
                        <label htmlFor="inputPropOwner" className="col-sm-2 col-form-label">Fastighetsägare</label>
                            <div className="col-md-10">
                                <select defaultValue="Choose..." id="inputPropOwner" className="form-control">
                                {
                                    this.state.officeOptions.map(ofc => <option>{ofc}</option>)
                                }
                                </select>
                            </div>
                        </div>
                    }
                    <div className="form-group row">
                        <label htmlFor="inputName" className="col-sm-2 col-form-label">Rubrik</label>
                        <div className="col-md-10">
                            <input className="form-control" id="inputName" name="Name" required />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputPropName" className="col-sm-2 col-form-label">Fastighetens namn</label>
                        <div className="col-md-10">
                            <input className="form-control" id="inputPropName" name="PropertyName"/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputPropDesi" className="col-sm-2 col-form-label">Fastighetens beteckning</label>
                        <div className="col-md-10">
                            <input className="form-control" id="inputPropDesi" name="PropertyDesignaton" required/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputContact" className="col-sm-2 col-form-label">Kontaktperson</label>
                        <div className="col-md-10">
                            <input className="form-control" id="inputContact" name="ContactPerson"/>
                        </div>
                    </div>
                    <div className="form-row">
                        <label className="col-sm-2 col-form-label">Hyresgäst</label>
                        <div className="form-group col-md-10">
                            <label htmlFor="inputAppliName">Namn</label>
                            <input type="text" className="form-control" id="inputAppliName" name="TenantName" />
                        </div>
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="form-group col-md-5">
                            <label htmlFor="inputEmail">Epost</label>
                            <input type="email" className="form-control" id="inputEmail" name="TenantEmail" />
                        </div>
                        <div className="form-group col-md-5">
                            <label htmlFor="inputPhone">Telefonnummer</label>
                            <input type="number" className="form-control" id="inputPhone" name="TenantPhone" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputDesc" className="col-sm-2 col-form-label">Beskrivning</label>
                        <div className="col-md-10">
                            <textarea name="Description" rows={4} className="form-control" aria-label="With textarea" id="inputDesc"></textarea>
                        </div>
                    </div>
                    <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-primary btn-block">Skapa ärende</button>
                </form>
                <div>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} >
                        <ModalHeader style={{ margin: "auto" }}>Ärende skapat</ModalHeader>
                        <ModalFooter style={{ margin:"auto" }}>
                            <Button color="primary" onClick={this.onCreated}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                </div>
           </div>
        )
    }
}
