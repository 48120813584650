import { title } from 'process';
import * as React from 'react'
import SubCategory from '../components/SubCategory';
import Urls from '../utils/Urls';
import { ListGroup, Modal, ModalHeader, ModalBody, ModalFooter, Alert} from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, Col, Row, FormFeedback, Spinner, InputGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

type Dictionary = { [index: string]: any }
interface Props { role: string };
interface State {
    error: string | null
    dropdownOpen: boolean
    schemeDb: Dictionary
    schemeLocal: Dictionary
    categories: Array<string>
    subCategories: Array<Dictionary>
    subCategoriesCollapsed: Array<boolean>
    selectedCategory: string
    modal: boolean
    modalText: string
    modalTitle: string
    changesAlert: boolean
    editModal: boolean
    createModal: boolean
    loading: boolean
    removeModal: boolean
    discardChangesModal: boolean
    modalDesc: string;
};

export default class AdminSchemePage extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props)

        this.state = {
            modal: false,
            modalText: "",
            modalTitle: "",
            editModal: false,
            createModal: false,
            changesAlert: false,
            error: null,
            dropdownOpen: false,
            schemeLocal: {},
            schemeDb: {},
            loading: true,
            removeModal: false,
            discardChangesModal: false,
            categories: [],
            subCategories: [],
            subCategoriesCollapsed: [],
            selectedCategory: "",
            modalDesc: ""
        }

        this.removeSubCat = this.removeSubCat.bind(this);
        this.saveSubCat = this.saveSubCat.bind(this);
        this.createNewSubCat = this.createNewSubCat.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.discardChanges = this.discardChanges.bind(this);
        this.createCat = this.createCat.bind(this);
        this.removeCat = this.removeCat.bind(this);
        this.SaveCatName = this.SaveCatName.bind(this);
        this.toggleRemoveModal = this.toggleRemoveModal.bind(this);
        this.togglediscardChangesModal = this.togglediscardChangesModal.bind(this);
       // this.onCategoryFieldChanged = this.onCategoryFieldChanged.bind(this);


    }

    componentDidMount() {
        fetch(Urls.GetLatestScheme)
            .then(res => {
                if (res.status == 200) {
                    return res.json();
                }
            })
            .then(json => {
                let scheme : Dictionary = json
                let localscheme = this.copyScheme(scheme);
                let mainCats = localscheme.order;
                this.setState({
                    schemeDb: scheme,
                    categories: mainCats,
                    subCategories: [...localscheme.categories[`${mainCats[0]}`].children],
                    subCategoriesCollapsed: [...localscheme.categories[`${mainCats[0]}`].children].map(() => false),
                    selectedCategory: mainCats[0],
                    schemeLocal: localscheme,
                    loading: false
                });
            })
            .catch(e => {
                console.log(`Problem fetching latest scheme: ${e}`);
            });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
    }

    private copyScheme(scheme: Dictionary) {
        let mainCats : Array<string> = [...scheme.order];
        let copy: Dictionary = {
            categories: {},
            order: mainCats
        }
        mainCats.forEach((cat) => {
            copy.categories[`${cat}`] = {
                children: [],
                description: scheme.categories[cat]['description']
            }
            scheme.categories[cat]['children'].forEach((item: Dictionary, i: number) => {
                copy.categories[cat]['children'].push(item)
            })
        })
        return copy;
    }

    private showModal(text: string, title: string) {
        this.setState({
            modalText: text,
            modalTitle: title,
            modal: true
        });
    }

    private toggleModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    private toggleCreateModal = () => {
        this.setState({
            createModal: !this.state.createModal,
            modalDesc : ""
        });
    }

    private toggleEditModal() {
        this.setState({
            editModal: !this.state.editModal,
            modalDesc: this.state.schemeLocal.categories[`${this.state.selectedCategory}`]['description']
        });
    }

    private toggleRemoveModal() {
        this.setState({
            removeModal: !this.state.removeModal
        })
    }

    private togglediscardChangesModal() {
        this.setState({
            discardChangesModal: !this.state.discardChangesModal
        })
    }

    private SaveCatName() {
        let titleInput = document.getElementById("changeNameInput") as HTMLInputElement;
        //let descInput = document.getElementById("changeDescInput") as HTMLInputElement;
        let desc = this.state.modalDesc;
        let currentDesc = this.state.schemeLocal.categories[`${this.state.selectedCategory}`]['description'];
        let changes = false;
        let inputName = titleInput.value.trim();
        let schemeCopy = { ... this.state.schemeLocal };
        let newCategories: Array<string> = this.state.categories;
        //Cannot be empty
        if (inputName.length < 1) {
            titleInput.classList.add("is-invalid")
            return;
        }
        //Must be unique
        else if (inputName != this.state.selectedCategory && this.state.categories.indexOf(inputName) > -1) {
            this.showModal("En kategori med det namnet finns redan", "Problem att spara");
            titleInput.classList.remove("is-invalid")
            return;
        }
        //Name has been changed
        else if (inputName != this.state.selectedCategory){
            schemeCopy.categories[`${inputName}`] = schemeCopy.categories[`${this.state.selectedCategory}`];
            delete schemeCopy.categories[this.state.selectedCategory];
            newCategories = [...this.state.categories];
            let index = newCategories.indexOf(this.state.selectedCategory);
            newCategories.splice(index, 1, inputName);  
            let orderIndex = schemeCopy.order.indexOf(this.state.selectedCategory);
            schemeCopy.order.splice(orderIndex, 1, inputName);
            changes = true;
        }
        //New description
        if (desc != currentDesc) {
            schemeCopy.categories[`${inputName}`]['description'] = desc;//descInput.value;
            changes = true;
        }
        titleInput.classList.remove("is-invalid")
        this.setState({
            schemeLocal: schemeCopy,
            selectedCategory: titleInput.value,
            categories: newCategories,
            changesAlert: changes
        }, () => {
                let categories = document.getElementById("categorySelect") as HTMLSelectElement;
                categories.selectedIndex = this.state.categories.indexOf(titleInput.value);
                this.toggleEditModal();
        })
        
    }

    private createCat() {
        let titleInput = document.getElementById("newNameInput") as HTMLInputElement;
        //let descInput = document.getElementById("newDescInput") as HTMLInputElement;
        let desc = this.state.modalDesc;
        let schemeCopy = { ... this.state.schemeLocal };
        let newName = titleInput.value.trim();
        //Cannot be empty
        if (newName.length < 1) {
            titleInput.classList.add("is-invalid")
            return;
        }
        //Must be unique
        else if (this.state.categories.indexOf(newName) > -1) {
            this.showModal("En kategori med det namnet finns redan", "Problem att skapa");
            titleInput.classList.remove("is-invalid")
            return;
        }
        titleInput.classList.remove("is-invalid")
        schemeCopy.categories[`${newName}`] = {
            children: [],
            description: desc//descInput.value
        };
        schemeCopy.order.push(newName);
        this.setState({
            schemeLocal: schemeCopy,
            subCategories: [],
            subCategoriesCollapsed: [],
            selectedCategory: newName,
            categories: [...schemeCopy.order],
            changesAlert: true
        }, () => {
                let categories = document.getElementById("categorySelect") as HTMLSelectElement;
                categories.selectedIndex = this.state.categories.indexOf(newName);
                this.toggleCreateModal();
        })
    }

    private removeCat() {
        let categories = document.getElementById("categorySelect") as HTMLSelectElement;
        if (categories.children.length < 2) {
            this.showModal("Det behöver finnas minst 1 kategori", "Problem att ta bort");
            return;
        }
        let schemeCopy = { ... this.state.schemeLocal };
        delete schemeCopy.categories[categories.value];
        schemeCopy.order.splice(categories.selectedIndex, 1);
        let newCategories = schemeCopy.order//Object.keys(schemeCopy);
        let nextIndex = categories.selectedIndex;
        if (nextIndex > newCategories.length - 1) {
            nextIndex = 0;
        }
        this.setState({
            schemeLocal: schemeCopy,
            subCategories: schemeCopy.categories[`${newCategories[nextIndex]}`].children,
            subCategoriesCollapsed: schemeCopy.categories[`${newCategories[nextIndex]}`].children.map(() => false),
            selectedCategory: newCategories[nextIndex],
            categories: newCategories,
            changesAlert: true,
            editModal: false,
            removeModal: false
        },
            () => { categories.selectedIndex = nextIndex})
    }

    private removeSubCat(category: string, subCat: Dictionary, subCatIndex: number) {
        let schemeCopy = { ... this.state.schemeLocal };
        let catChildren: Array<Dictionary> = schemeCopy.categories[`${category}`].children;
        catChildren.splice(subCatIndex, 1);
        let collapseStatuses = [...this.state.subCategoriesCollapsed];
        collapseStatuses.splice(subCatIndex, 1);
        this.setState({
            schemeLocal: schemeCopy,
            subCategories: catChildren,
            subCategoriesCollapsed: collapseStatuses,
            changesAlert: true
        });
    }

    private saveSubCat(category: string, subCat: Dictionary, newSubCat: Dictionary, subCatIndex:number) {
        let schemeCopy = { ... this.state.schemeLocal };
        let catChildren: Array<Dictionary> = schemeCopy.categories[`${category}`].children;
        catChildren[subCatIndex] = newSubCat;
        this.setState({
            schemeLocal: schemeCopy,
            subCategories: catChildren,
            changesAlert: true
        });
    }

    private createNewSubCat() {
        let schemeCopy = { ... this.state.schemeLocal };
        let catChildren: Array<Dictionary> = schemeCopy.categories[`${this.state.selectedCategory}`].children;
        let newSubCat: Dictionary = { 'name': '', 'description': '' };
        catChildren.push(newSubCat);
        let collapseStatuses = [...this.state.subCategoriesCollapsed];
        collapseStatuses.push(true);
        
        this.setState({
            schemeLocal: schemeCopy,
            subCategories: catChildren,
            subCategoriesCollapsed: collapseStatuses,
            changesAlert: true
        });
    }

    private discardChanges() {
        let mainCats = this.state.schemeDb.order;
        this.setState({
            categories: [...mainCats],
            subCategories: [...this.state.schemeDb.categories[`${mainCats[0]}`].children],
            selectedCategory: mainCats[0],
            subCategoriesCollapsed: [...this.state.schemeDb.categories[`${mainCats[0]}`].children].map(() => false),
            schemeLocal: { ... this.state.schemeDb },
            changesAlert: false
        }, () => {
            let categories = document.getElementById("categorySelect") as HTMLSelectElement;
                categories.value = mainCats[0];
                this.togglediscardChangesModal();
        });
    }

    private validateScheme = (scheme: Dictionary) => {
        let categoriesNames = scheme.order;
        let categories: Dictionary = scheme.categories;
        let validationSuccess = true;
        categoriesNames.forEach((cat:string) => {
            let subCatNames = categories[cat].children.map((x: Dictionary) => x.name);
            if (subCatNames.length == 0) {
                return;
            }
            //Duplicate check and empty title check
            if ((new Set(subCatNames)).size !== subCatNames.length || subCatNames.includes("")) {
                validationSuccess = false;
            }
        })
        return validationSuccess;
    }

    private saveChanges() {
        if (!this.validateScheme(this.state.schemeLocal)) {
            this.showModal("Kontrollera att alla punkter har ett namn som är unikt för sin kategori", "Problem att spara");
            return;
        }
        this.setState({
            loading: true
        });
        

        let updatedScheme = {
            Format: JSON.stringify(this.state.schemeLocal)
        };
        fetch(Urls.UpdateScheme, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedScheme)
        }).then(res => {
            if (res.status == 200) {
                let newScheme = this.copyScheme(this.state.schemeLocal);
                let mainCats = newScheme.order;
                this.setState({
                    categories: mainCats,
                    subCategories: [...newScheme.categories[`${mainCats[0]}`].children],
                    selectedCategory: mainCats[0],
                    schemeDb: newScheme,
                    changesAlert: false,
                    loading: false
                }, () => {
                        let categories = document.getElementById("categorySelect") as HTMLSelectElement;
                        categories.value = mainCats[0]
                        this.showModal("Dina ändringar har sparats och schemat har uppdaterats.", "Schema sparat");
                });
            }
        }).catch(e => {
            console.log(`Problem when updating the scheme: ${e}`);
        });
    }

    private toggleSubCatCollapse = (index: number) => {
        let collapseStatuses = [...this.state.subCategoriesCollapsed];
        collapseStatuses[index] = !collapseStatuses[index];
        this.setState({
            subCategoriesCollapsed: collapseStatuses
        })
    }

    private onSelectOption(event: React.ChangeEvent<HTMLSelectElement>) {
        let selectedCategory: string = event.target.value;
        this.setState({
            subCategories: this.state.schemeLocal.categories[`${selectedCategory}`].children,
            selectedCategory: selectedCategory,
            subCategoriesCollapsed: this.state.schemeLocal.categories[`${selectedCategory}`].children.map(() => false)
        })
    }

    private onChangeModalDesc = (content: string) => {
        this.setState({
            modalDesc: content
        });
    }

    //private timeoutId: any;
    //private onCategoryFieldChanged() {
    //    clearTimeout(this.timeoutId);
    //    this.timeoutId = setTimeout(function (component: AdminSchemePage) {
    //        component.SaveCatName()
    //    }, 500, this);
    //}

    render() {
        let categoryIndex = this.state.categories.indexOf(this.state.selectedCategory);

        return (
            <div>
                {
                    this.state.loading &&
                    <Spinner color="primary"/>
                }
                {
                    !this.state.loading &&
                    <Form style={{ maxWidth: "700px", margin: "auto" }}>
                        <FormGroup>
                            <h5>Kategorier</h5>
                            <Row>
                                <Col md={6}>
                                    <select id="categorySelect" onChange={this.onSelectOption.bind(this)} className="form-control">
                                        {
                                            this.state.categories.map(cat => <option key={cat}>{cat}</option>)
                                        }
                                    </select>
                                </Col>
                                <Col md={3}>
                                    <Button size="md" className="width-100" onClick={this.toggleEditModal} color="primary">
                                        <FontAwesomeIcon icon={faEdit} />
                                        <span style={{ marginLeft: "7px" }}>Redigera</span>
                                    </Button>
                                </Col>
                                <Col md={3}>
                                    <Button size="md" className="width-100" onClick={this.toggleCreateModal} color="primary">
                                        <FontAwesomeIcon icon={faPlus} />
                                        <span style={{ marginLeft: "7px" }}>Ny kategori</span>
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <h5>Punkter inom kategori</h5>
                            <ListGroup>
                                {
                                    
                                    this.state.subCategories.map((subCat, i) => {
                                        return <SubCategory toggleCollapse={this.toggleSubCatCollapse} expanded={this.state.subCategoriesCollapsed[i]} listIndex={i} categoryIndex={categoryIndex} category={this.state.selectedCategory} subCategory={subCat} removeSubCat={this.removeSubCat} saveSubCat={this.saveSubCat} />
                                    })
                                }
                            </ListGroup>
                        </FormGroup>
                        <FormGroup>
                            <Button size="md" className="width-100" onClick={this.createNewSubCat} color="primary">
                                <FontAwesomeIcon icon={faPlus} />
                                <span style={{ marginLeft: "7px" }}>Ny punkt</span>
                            </Button>
                        </FormGroup>
                        <FormGroup>
                            <Alert isOpen={this.state.changesAlert} color="primary">
                                Du har osparade ändringar, glöm inte spara ändringarna innan du lämnar sidan.
                        </Alert>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col md={6}>
                                    <Button disabled={!this.state.changesAlert} onClick={this.saveChanges} className="form-control" color="success">Spara ändringar</Button>
                                </Col>
                                <Col md={6}>
                                    <Button onClick={this.togglediscardChangesModal} className="form-control" color="danger">Återställ schema</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                }
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} >
                    <ModalHeader style={{ margin: "auto" }}>{this.state.modalTitle}</ModalHeader>
                    <ModalBody style={{ margin: "auto" }}>
                        {this.state.modalText}
                        </ModalBody>
                    <ModalFooter style={{ margin: "auto" }}>
                        <Button color="primary" onClick={this.toggleModal}>Ok</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.createModal} toggle={this.toggleCreateModal} >
                    <ModalBody>
                        <FormGroup>
                            <Label>Titel</Label>
                            <Input id="newNameInput"/>
                            <FormFeedback>Får inte vara tom</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Beskrivning</Label>
                            <ReactQuill 
                                onChange={(content) => { this.onChangeModalDesc(content) }} value={this.state.modalDesc}/>
                            {/*<Input type="textarea" rows={4} id="newDescInput" />*/}
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col md={3}>
                                    <Button className="width-100" color="primary" onClick={this.createCat}>Skapa</Button>
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <Button className="width-100" color="secondary" onClick={this.toggleCreateModal}>Stäng</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.editModal} toggle={this.toggleEditModal} keyboard={false} backdrop="static">
                    <ModalBody>
                        <FormGroup>
                            <Label>Titel</Label>
                            <Input id="changeNameInput" defaultValue={this.state.selectedCategory}/>
                            <FormFeedback>Får inte vara tom</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Beskrivning</Label>
                            {/*<Input type="textarea" rows={4} id="changeDescInput" defaultValue={catDesc} />*/}
                            <ReactQuill value={this.state.modalDesc}
                                onChange={(content) => { this.onChangeModalDesc(content) }} />
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col md={3}>
                                    <Button className="width-100" color="danger" onClick={this.toggleRemoveModal}>Ta bort</Button>
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <Button className="width-100" color="secondary" onClick={this.SaveCatName}>Stäng</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.removeModal} toggle={this.toggleRemoveModal} >
                    <ModalHeader style={{ margin: "auto" }}>Vill du ta bort den här kategorin och alla punkter inom den?</ModalHeader>
                    <ModalFooter style={{ margin: "auto" }}>
                        <Button color="danger" onClick={this.removeCat}>Ja</Button>
                        <Button color="primary" onClick={this.toggleRemoveModal}>Avbryt</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.discardChangesModal} toggle={this.togglediscardChangesModal} >
                    <ModalHeader style={{ margin: "auto" }}>Vill kasta alla ändringar du gjort?</ModalHeader>
                    <ModalFooter style={{ margin: "auto" }}>
                        <Button color="danger" onClick={this.discardChanges}>Ja</Button>
                        <Button color="primary" onClick={this.togglediscardChangesModal}>Avbryt</Button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}
