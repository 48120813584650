import { faCaretDown, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { title } from 'process';
import * as React from 'react'
import { ListGroup, ListGroupItem, UncontrolledCollapse, ModalFooter, Modal, ModalHeader } from 'reactstrap';
import { Button, ButtonToggle, Form, FormGroup, Label, Input, FormFeedback, FormText, Col, Row, Collapse } from 'reactstrap';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';


type Dictionary = { [index: string]: any }
interface Props {
    category: string
    subCategory: Dictionary
    removeSubCat: Function
    saveSubCat: Function
    listIndex: number
    categoryIndex: number
    expanded: boolean
    toggleCollapse : Function
};
interface State {
    error: string | null
    //dropdownOpen: boolean
    saveDisabled: boolean
    removeModal: boolean
};

export default class SubCategory extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props)

        this.state = {
            error: null,
            //dropdownOpen: this.props.expanded,
            saveDisabled: true,
            removeModal: false
        }

        this.onRemove = this.onRemove.bind(this);
        this.onSave = this.onSave.bind(this);
        this.toggleRemoveModal = this.toggleRemoveModal.bind(this);
        this.onFieldsChanged = this.onFieldsChanged.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.onTitleFocus = this.onTitleFocus.bind(this);
        this.onTitleBlur = this.onTitleBlur.bind(this);
    }
    private toggleId: string = "";
    private descId: string = "";
    private titleId: string = "";

    componentDidMount() {
        //let titleInput = document.getElementById(this.titleId) as HTMLInputElement;
        //let descInput = document.getElementById(this.descId) as HTMLInputElement;
        //this.onChange(titleInput, 'name');
        //this.onChange(descInput, 'description');
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (`${this.props.listIndex}${this.props.categoryIndex}` != `${prevProps.listIndex}${prevProps.categoryIndex}`) {
            //this.setState({
            //    dropdownOpen: this.props.expanded
            //})
        }
    }

    //private onChange(element: HTMLInputElement, prop: string) {
    //    element.addEventListener('keyup', (ev: any) => {
    //        console.log("keyupu");
    //        if (ev.target.value != this.props.subCategory[`${prop}`]) {
    //            this.setState({
    //                saveDisabled: false
    //            });
    //        }
    //    });
    //}

    private onSave(content? : string) {
        let titleInput = document.getElementById(this.titleId) as HTMLInputElement;
        //let descInput = document.getElementById(this.descId) as HTMLInputElement;
        //if (titleInput.value.trim().length > 0) {
        //    titleInput.classList.remove("is-invalid")
        //}
        //else {
        //    titleInput.classList.add("is-invalid")
        //}
        let updatedSub: Dictionary = { 'name': titleInput.value, 'description': content }
        this.props.saveSubCat(this.props.category, this.props.subCategory, updatedSub, this.props.listIndex);
    }

    private onRemove(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.toggleRemoveModal();
        this.props.removeSubCat(this.props.category, this.props.subCategory, this.props.listIndex);
    }

    private toggleRemoveModal() {
        this.setState({
            removeModal: !this.state.removeModal
        })
    }

    private toggleCollapse() {
        this.props.toggleCollapse(this.props.listIndex);
        //this.setState({
        //    dropdownOpen: !this.state.dropdownOpen
        //})
    }

    private timeoutId: any;
    private onFieldsChanged(content?: string) {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(function (component: SubCategory) {
            component.onSave(content);
        }, 500, this);
    }

    private onTitleFocus() {
        this.titleInputFocused = true;
    }

    private onTitleBlur() {
        this.titleInputFocused = false;
    }

    private titleInputFocused: boolean = false;
    render() {
        this.toggleId = `toggle${this.props.categoryIndex}${this.props.listIndex}`;
        this.descId = `desc${this.props.categoryIndex}${this.props.listIndex}`;
        this.titleId = `title${this.props.categoryIndex}${this.props.listIndex}`;
        return (
            <ListGroupItem key={`${this.props.category}-${this.props.subCategory['name']}` } style={{ padding: 0 }}>
                <Button color="primary" onClick={this.toggleCollapse} block outline className="btn-no-border btn-no-highlight text-left">
                    
                    {
                        this.props.subCategory['name'] ?
                        this.props.subCategory['name'] :
                        <span>
                            <FontAwesomeIcon className="danger" style={{ float: "left", marginTop:".2rem" }} icon={faExclamation} />
                            <span style={{marginLeft:"7px"}}><i>Ingen titel</i></span>
                        </span>
                    }
                    <FontAwesomeIcon style={{ float: "right" }} icon={faCaretDown} />
                </Button>
                <Collapse isOpen={this.props.expanded}>
                    <div style={{ margin: "auto", maxWidth: "80%" }}>
                        <FormGroup>
                            <Label>Titel</Label>
                            <Input required id={this.titleId} defaultValue={this.props.subCategory['name']} placeholder="Ange titel" onChange={this.onFieldsChanged} onFocus={this.onTitleFocus} onBlur={this.onTitleBlur} autoFocus={this.titleInputFocused}/>
                            <FormFeedback>Får inte vara tom</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Beskrivning</Label>
                            <ReactQuill defaultValue={this.props.subCategory['description'] != undefined ? this.props.subCategory['description'].toString() : ""}
                                onChange={this.onFieldsChanged} />
                            {/*<Input type="textarea" rows={4} id={this.descId} defaultValue={this.props.subCategory['description']} onChange={this.onFieldsChanged}/>*/}
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col md={3}>
                                    <Button className="width-100" onClick={this.toggleRemoveModal} color="danger">
                                        Ta bort
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </div>
                </Collapse>
                <Modal isOpen={this.state.removeModal} toggle={this.toggleRemoveModal} >
                    <ModalHeader style={{ margin: "auto" }}>Vill du ta bort den här punkten?</ModalHeader>
                    <ModalFooter style={{ margin: "auto" }}>
                        <Button color="danger" onClick={this.onRemove}>Ja</Button>
                        <Button color="primary" onClick={this.toggleRemoveModal}>Avbryt</Button>
                    </ModalFooter>
                </Modal>
            </ListGroupItem>

        )
    }
}
