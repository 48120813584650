type Dictionary = { [index: string]: any }

//const exportInfoFields: Dictionary = {
//    "id": {
//        title: "Felanmälan Id"
//    },
//    "name": {
//        title: "Namn"
//    },
//    "status": {
//        title: "Status"
//    },
//    "office": {
//        title: "Fastighetsägare",
//        getValue: (data: any) => {
//            return data.name;
//        }
//    },
//    "contactPerson": {
//        title: "Kontaktperson"
//    },
//    "propertyDesignaton": {
//        title: "Fastighetsbeteckning"
//    },
//    "propertyName": {
//        title: "Fastighetsnamn"
//    },
//    "created": {
//        title: "Skapad",
//        getValue: (data: any) => {
//            return data.substring(0, 10);
//        }
//    },
//    "createdByNavigation": {
//        title: "Skapad Av",
//        getValue: (data: any) => {
//            return data.userName;
//        }
//    },
//}

const modalInfoFields: Dictionary = {
    "id": {
        title: "ÄrendeId"
    },
    "name": {
        title: "Rubrik"
    },
    "status": {
        title: "Status"
    },
    "office": {
        title: "Fastighetsägare",
        getValue: (data: any) => {
            return data.name;
        }
    },
    "contactPerson": {
        title: "Kontaktperson"
    },
    "propertyDesignaton": {
        title: "Fastighetsbeteckning"
    },
    "propertyName": {
        title: "Fastighetsnamn"
    },
    "description": {
        title: "Beskrivning"
    },
    "tenantName": {
        title: "Hyresgäst namn"
    },
    "tenantEmail": {
        title: "Hyresgäst epost"
    },
    "tenantPhone": {
        title: "Hyresgäst telefon"
    },
    "created": {
        title: "Skapad",
        getValue: (data: any) => {
            return data.substring(0, 10);
        }
    },
    "createdByNavigation": {
        title: "Skapad Av",
        getValue: (data: any) => {
            return data.userName;
        }
    },
    "updated": {
        title: "Ändrad",
        getValue: (data: any) => {
            return data.substring(0, 10);
        }
    },
    "updatedByNavigation": {
        title: "Ändrad Av",
        getValue: (data: any) => {
            return data.userName;
        }
    }
}

//export function mapErrandsDataToCSV(errands: Array<Dictionary>) {
//    let csvContent = "data:text/csv;charset=utf-8,";
//    let headerFields: Array<string> = Object.keys(exportInfoFields);
//    let header = headerFields.map(header => exportInfoFields[header].title)
//    csvContent += header.join(',') + "\r\n";
//    errands.forEach((errand) => {
//        let row = "";
//        headerFields.forEach((field, i) => {
//            if (i > 0) {
//                row += ",";
//            }
//            if (exportInfoFields[field].getValue) {
//                row += exportInfoFields[field].getValue(errand[field]);
//            }
//            else if (!errand[field]) {
//                row += "";
//            }
//            else {
//                row += errand[field];
//            }
            
//        })
//        csvContent += row + "\r\n";
//    })
//    return csvContent;
//}

function mapErrandDataToModalInfo(errand: Dictionary) {
    let modalInfo: Array<Dictionary> = [];
    Object.keys(modalInfoFields).forEach(key => {
        modalInfo.push(getModalInfoItem(key, errand, modalInfoFields));
    })
    return modalInfo;
}

function getModalInfoItem(key: string, errand:Dictionary, fields: Dictionary) {
    let field = fields[key];
    let item: Dictionary = {
        key: field.title,
        value: errand[key]
    };
    if (field.getValue && item.value !== undefined && item.value !== null) {
        console.log(item)
        item.value = field.getValue(item.value)
    }
    return item;
}

export default mapErrandDataToModalInfo;
