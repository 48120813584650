import * as React from "react";
import { Col, Row, Container } from "reactstrap";

export interface IWelcomePageProps {}

export interface IWelcomePageState {}

export default class WelcomePage extends React.Component<
  IWelcomePageProps,
  IWelcomePageState
> {
  constructor(props: IWelcomePageProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={6}>
          <p style={{ fontWeight: 600 }}>
            <h3>Välkommen!</h3>
            Inomhusmiljö är komplicerat, både vad gäller teknik och funktion men
            även med avseende på regler och ansvar. Kvaliteten på inomhusmiljön
            påverkas av byggnadstekniska aspekter och brukarnas beteende. Om
            någon upplever brister i inomhusmiljön så är det viktigt att tidigt
            identifiera grundorsaken. Som hyresgäst är det lätt att tro att
            problemet ligger i lokalen/bostaden om kvaliteten på inomhusmiljön
            är sämre. Så är inte alltid fallet varför det är viktigt att genom
            samarbete med hyresgästen hitta orsaken till problemet.
            <br />
            <br />
            Till er hjälp i detta ofta svåra arbete har denna digitala
            checklista arbetats fram i ett inomhusklimatnätverk i
            Gävle/Dalaregionen, inom Fastighetsägarna MittNord, bestående av ett
            antal fastighetsägare, tillsammans med Kjell Andersson, leg.läkare
            och konsult inom miljö och hälsa, och Per-Erik Hjelmer, Aquademica,
            specialist inomhusmiljö. Välkommen till CheckIN!
          </p>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <img style={{marginTop: 12}} width={"100%"} src="/Flodesschema-inomhusklimat.png" />
        </Col>
      </Row>
    );
  }
}
