import * as React from 'react'
import Urls from '../utils/Urls';
import { GlobalRoles, AdminRoles, Roles } from '../utils/Roles';
import ErrandSubCategory from '../components/ErrandSubCategory';
import { ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Table, InputGroup, Media } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, Col, Row, FormFeedback, Spinner } from 'reactstrap';
import MapErrandData from '../utils/ErrandInfo';

import {
    Redirect,
    withRouter
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilePdf, faInfoCircle, faRedo, faTimes } from '@fortawesome/free-solid-svg-icons';

type Dictionary = { [index: string]: any }
interface Props {
    match: any
};
interface State {
    errand: Dictionary
    isLoading: boolean
    isAllowed: boolean
    categories: Array<Dictionary>
    subCategories: Array<Dictionary>
    selectedCategory: Dictionary
    scheme: Dictionary
    currentStatus: Dictionary
    modalInfo: Array<Dictionary>
    infoModal: boolean
    categoryModal: boolean
    errandDone: boolean
    errandDoneModal: boolean;
};

export default withRouter(class ViewErrandPage extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props)


        this.state = {
            errand: {},
            isLoading: true,
            isAllowed: true,
            categories: [],
            subCategories: [],
            selectedCategory: {},
            scheme: {},
            currentStatus: {},
            modalInfo: [],
            infoModal: false,
            categoryModal: false,
            errandDoneModal: false,
            errandDone: true
        }

            
        let office: string = this.props.match.params.office
        let errandId: string = this.props.match.params.id;
        
        fetch(Urls.WhoIsUser)
            .then(res => {
                if (res.status == 200) {
                    return res.json();
                }
            })
            .then(user => {
                let isElevated = GlobalRoles.includes(user.role);
                if (isElevated || user.office.name == office) {
                    this.init( parseInt(errandId), isElevated);
                }
                else {
                    this.setState({
                        isAllowed: false
                    })
                }
            })
            .catch(e => {
                console.log(`Problem fetching user: ${e}`);
            })
        this.init = this.init.bind(this);
        this.changeProp = this.changeProp.bind(this);
        this.toggleInfoModal = this.toggleInfoModal.bind(this);
        this.toggleCategoryModal = this.toggleCategoryModal.bind(this);
        //this.init(parseInt(errandId), true);
    }

    private async init(errandId: Number, isElevated: boolean) {
        let url = isElevated ? Urls.GetErrandByIdEl : Urls.GetErrandById;
        fetch(`${url}/${errandId}`)
            .then(res => {
                if (res.status == 200) {
                    return res.json();
                }
                else {
                    this.setState({
                        isLoading: false,
                        isAllowed: false,
                    })
                }
            })
            .then(errand => {
                let scheme: Dictionary = JSON.parse(errand.scheme.format);
                let categories: Array<Dictionary> = scheme.order.map((cat:string) => {
                    return {
                        title: cat,
                        desc: scheme.categories[cat].description
                    }
                });
                let subCategories: Array<Dictionary> = scheme.categories[categories[0].title].children;
                let currentStatus = errand.currentState ? JSON.parse(errand.currentState) : {};
                this.setState({
                    isLoading: false,
                    isAllowed: true,
                    errand: errand,
                    scheme: scheme,
                    categories: categories,
                    subCategories: subCategories,
                    selectedCategory: categories[0],
                    currentStatus: currentStatus,
                    modalInfo: MapErrandData(errand),
                    errandDone: errand.status == "Klar" ? true : false
                })
            })
            .catch(e => {
                console.log(`Problem fetching errand: ${e}`);
            })
    }

    private onSelectOption(event: React.ChangeEvent<HTMLSelectElement>) {
        let newValue: string = event.target.value;
        let selectedCategory : Dictionary = this.state.categories.filter(cat => cat.title == newValue)[0];
        this.setState({
            subCategories: this.state.scheme.categories[`${selectedCategory.title}`].children,
            selectedCategory: selectedCategory
        })
    }

    private changeProp(subCat: string, propName: string, newValue: string, callback : Function) {
        let status = { ...this.state.currentStatus };
        let category = this.state.selectedCategory.title;
        if (!status[category]) {
            status[category] = {}
        }
        if (!status[category][subCat]) {
            status[category][subCat] = {}
        }
        status[category][subCat][propName] = newValue;
        let data = {
            Id: this.state.errand.id,
            CurrentState: JSON.stringify(status)
        }
        fetch(Urls.UpdateErrand, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.status == 200) {
                    this.setState({
                        currentStatus: status
                    }, () => {
                        callback();
                    });
                }
            })
            .catch(e => {
                console.log(`Problem updating the errand status: ${e}`)
            })
        
    }

    private toggleInfoModal() {
        this.setState({
            infoModal: !this.state.infoModal
        })
    }

    private toggleCategoryModal() {
        this.setState({
            categoryModal: !this.state.categoryModal
        })
    }

    private onFinished = () => {
        let errand = { ...this.state.errand }
        let newStatus = this.state.errandDone ? "Pågående" : "Klar";
        let data = {
            Id: errand.id,
            Status: newStatus
        }
        fetch(Urls.FinishErrand, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.status == 200) {
                    errand.status = newStatus;
                    this.setState({
                        errand: errand,
                        errandDone: !this.state.errandDone,
                        errandDoneModal: false
                    })
                }
            })
            .catch(e => {
                console.log(`Problem marking errand as done: ${e}`);
            })
    }

    private toggleErrandDoneModal = () => {
        this.setState({
            errandDoneModal: !this.state.errandDoneModal
        })
    }

    private openPdf = () => {
        window.open(Urls.GetPDF, '_blank');
    }

    render() {
        let categoryIndex = this.state.categories.findIndex(cat => cat.title == this.state.selectedCategory.title);
        var x = window.matchMedia("(max-width: 500px)")
        let nameColumns = 7;
        if (x.matches) {
            nameColumns = 6;
        }
        return (
            <div>
                {
                    this.state.isLoading &&
                    <Spinner color="primary" />
                }
                {
                    !this.state.isAllowed &&
                    <Redirect to="/errands"/>
                }
                {
                    (!this.state.isLoading && this.state.isAllowed) &&
                    <div style={{ maxWidth: "700px", margin: "auto" }}>
                        <FormGroup>
                            <Row style={{alignItems:"flex-end"}}>
                                <h5>Ärende #{this.state.errand.id} {this.state.errand.name}</h5>
                                <Button outline className="btn-no-border" onClick={this.toggleInfoModal}>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                </Button>
                            </Row>
                        </FormGroup>
                        {
                            this.state.errandDone &&
                            <FormGroup>
                                <Alert color="primary">
                                    Det här ärendet är klarmarkerat.
                                </Alert>
                            </FormGroup>
                        }
                        <FormGroup>
                            <h5>Kategorier</h5>
                            <InputGroup>
                                <select id="categorySelect" onChange={this.onSelectOption.bind(this)} className="form-control width-90" style={{marginRight:"10px"}}>
                                {
                                        this.state.categories.map(cat =>  <option key={cat.title}>{cat.title}</option>)
                                }
                                </select>
                                <Button outline className="btn-no-border width-10" onClick={this.toggleCategoryModal}>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </Button>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <ListGroupItem  className="btn-no-border" style={{ padding: "0.375rem 0.75rem" }}>
                                <Row style={{ alignItems: "center" }}>
                                    <Col xs={nameColumns} style={{ padding: "0px" }}>
                                        <h5>Åtgärdspunkter</h5>
                                    </Col>
                                    <Col xs={1}>
                                    </Col>
                                    <Col xs={1}>
                                        <FontAwesomeIcon icon={faCheck} className="success" />
                                    </Col>
                                    <Col xs={1}>
                                        <FontAwesomeIcon icon={faTimes} className="danger"/>
                                    </Col>
                                    <Col xs={1}>
                                    </Col>
                                    <Col xs={1}>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroup>
                                {
                                    this.state.subCategories.map((subCat, i) => {
                                        let categoryStatus = this.state.currentStatus[this.state.selectedCategory.title];
                                        let subCatStatus = {}
                                        if (categoryStatus) {
                                            if (categoryStatus[subCat.name]) {
                                                subCatStatus = categoryStatus[subCat.name]
                                            }
                                        }
                                        return <ErrandSubCategory key={i} listIndex={i} errandDone={this.state.errandDone} officeName={this.state.errand.office.name} categoryIndex={categoryIndex} currentStatus={subCatStatus} subCategory={subCat} changeProp={this.changeProp} />
                                    })
                                }
                            </ListGroup>
                        </FormGroup>
                        {
                            !this.state.errandDone ?
                            <FormGroup>
                                <Button size="md" className="width-100" onClick={this.toggleErrandDoneModal} color="primary">
                                    <FontAwesomeIcon icon={faCheck} />
                                    <span style={{ marginLeft: "7px" }}>Klarmarkera</span>
                                </Button>
                            </FormGroup>
                            :
                            <FormGroup>
                                <Button size="md" className="width-100" onClick={this.toggleErrandDoneModal} color="secondary">
                                    <FontAwesomeIcon icon={faRedo} />
                                    <span style={{ marginLeft: "7px" }}>Ångra klarmarkering</span>
                                </Button>
                            </FormGroup>
                        }
                        
                        <Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal}>
                            <ModalHeader toggle={this.toggleInfoModal}>Information</ModalHeader>
                            <ModalBody>
                                <Table>
                                    <tbody>
                                        {
                                            this.state.modalInfo.map(info => {
                                                return (
                                                    <tr>
                                                        <th scope="row">{info.key}</th>
                                                        <td>{info.value}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.categoryModal} toggle={this.toggleCategoryModal}>
                            <ModalHeader toggle={this.toggleCategoryModal}>{this.state.selectedCategory.title}</ModalHeader>
                            <ModalBody dangerouslySetInnerHTML={{ __html: this.state.selectedCategory.desc.replace(/[\n\r]/g, "<br>") }}>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.errandDoneModal} toggle={this.toggleErrandDoneModal} >
                            {
                                !this.state.errandDone ? <>
                                    <ModalHeader style={{ margin: "auto" }}>Vill du klarmarkera detta ärende?</ModalHeader>
                                    <ModalFooter style={{ margin: "auto" }}>
                                        <Button color="primary" onClick={this.onFinished}>Ja</Button>
                                        <Button color="secondary" onClick={this.toggleErrandDoneModal}>Nej</Button>
                                    </ModalFooter></>
                                    :
                                    <>
                                    <ModalHeader style={{ margin: "auto" }}>Vill du ta bort klarmarkeringen på detta ärende?</ModalHeader>
                                    <ModalFooter style={{ margin: "auto" }}>
                                        <Button color="primary" onClick={this.onFinished}>Ja</Button>
                                        <Button color="secondary" onClick={this.toggleErrandDoneModal}>Nej</Button>
                                    </ModalFooter></>

                            }
                        </Modal>
                    </div>
                }
            </div >
        )
    }
})