import { title } from 'process';
import Urls from '../utils/Urls';
import * as React from 'react'
import { ListGroup, ListGroupItem, Collapse } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Button, ButtonToggle, Form, FormGroup, Label, Input, FormFeedback, FormText, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';


type Dictionary = { [index: string]: any }
interface Props {
    offices: Array<Dictionary>
    office: Dictionary
    user: Dictionary
    disableUser: Function
    onUserChangeOffice: Function
    listIndex: number
    officeIndex: number
    isGlobalAdmin: boolean
};
interface State {
    error: string | null
    dropdownOpen: boolean
    saveDisabled: boolean
    officeChanged: boolean
    enteredNewPass: boolean
    collapseIsOpen: boolean
    removeModal: boolean
};

export default class UserListItem extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props)

        this.state = {
            error: null,
            dropdownOpen: false,
            saveDisabled: true,
            officeChanged: false,
            enteredNewPass: false,
            collapseIsOpen: false,
            removeModal: false
        }

        this.onRemove = this.onRemove.bind(this);
        this.onSaveOffice = this.onSaveOffice.bind(this);
        this.onOfficeChange = this.onOfficeChange.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.toggleRemoveModal = this.toggleRemoveModal.bind(this);

    }
    private toggleId: string = "";
    private passId: string = "";
    private selectId: string = "";

    componentDidMount() {
        let passInput = document.getElementById(this.passId) as HTMLInputElement;
        passInput.addEventListener('keyup', (e: KeyboardEvent) => {
            if (passInput.value.length > 0) {
                this.setState({
                    enteredNewPass: true
                })
            }
            else {
                this.setState({
                    enteredNewPass: false
                })
            }
        });
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.office != prevProps.office || this.props.user['Email'] != prevProps.user['Email']) {
            let officeSelect = document.getElementById(this.selectId) as HTMLSelectElement;
            if (officeSelect) {
                officeSelect.value = this.props.office.name;
            }
            this.setState({
                collapseIsOpen: false
            });
        }
    }

    private toggleRemoveModal() {
        this.setState({
            removeModal: !this.state.removeModal
        });
    }

    private toggleCollapse() {
        this.setState({
            collapseIsOpen: !this.state.collapseIsOpen
        })
    }

    private onSaveOffice(event: any) {
        event.preventDefault();
        event.stopPropagation();
        let officeSelect = document.getElementById(this.selectId) as HTMLSelectElement;
        this.props.onUserChangeOffice(this.props.user['Email'], officeSelect.value);
        this.setState({
            officeChanged: false
        });
    }

    private onChangePassword(event: any) {
        let passInput = document.getElementById(this.passId) as HTMLInputElement;
        let data = {
            UserEmail: this.props.user['Email'],
            NewPassword: passInput.value
        }
        fetch(Urls.UpdateUserPassword, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.status == 200) {
                    passInput.value = "";
                    this.setState({
                        enteredNewPass: false
                    });
                }
            })
            .catch(e => {
                console.log(`Problem updating user password:  ${e}`);
            });

    }

    private async onRemove (event: any) {
        event.preventDefault();
        event.stopPropagation();
        await this.props.disableUser(this.props.user);
        this.toggleRemoveModal();
    }

    private onOfficeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        let selectedOffice: string = event.target.value;
        if (selectedOffice != this.props.office.name) {
            this.setState({
                officeChanged: true
            })
        }
        else {
            this.setState({
                officeChanged: false
            })
        }
    }

    render() {
        this.toggleId = `toggle${this.props.officeIndex}${this.props.listIndex}`;
        this.passId = `pass${this.props.officeIndex}${this.props.listIndex}`;
        this.selectId = `title${this.props.officeIndex}${this.props.listIndex}`;
        return (
            <ListGroupItem  style={{ padding: 0 }}>
                <Button color="primary" onClick={this.toggleCollapse} id={this.toggleId} block outline className="btn-no-border btn-no-highlight text-left">
                    {this.props.user['Email']}
                    <FontAwesomeIcon style={{ float: "right" }} icon={faCaretDown} />
                </Button>
                <Collapse isOpen={this.state.collapseIsOpen}>
                    <div style={{ margin: "auto", maxWidth: "80%" }}>
                        {
                            this.props.isGlobalAdmin &&
                            <FormGroup>
                                <Label>Fastighetsägare</Label>
                                <select id={this.selectId} defaultValue={this.props.office.name} onChange={this.onOfficeChange} className="form-control" style={{ marginBottom: "0.5rem" }}>
                                    {
                                        this.props.offices.map(ofc => <option key={ofc.name}>{ofc.name}</option>)
                                    }
                                </select>
                                <Button onClick={this.onSaveOffice} hidden={!this.state.officeChanged} color="primary">Förflytta användare</Button>
                            </FormGroup>
                        }
                        <FormGroup>
                            <Label>Ange nytt lösenord</Label>
                            <Input style={{ marginBottom: "0.5rem" }} type="password" id={this.passId}/>
                            <Button onClick={this.onChangePassword} hidden={!this.state.enteredNewPass} color="primary">Byt lösenord</Button>
                        </FormGroup>
                        <FormGroup>
                            <Button onClick={this.toggleRemoveModal} color="danger">Ta bort</Button>
                        </FormGroup>
                    </div>
                </Collapse>
                <Modal isOpen={this.state.removeModal} toggle={this.toggleRemoveModal} >
                    <ModalHeader style={{ margin: "auto" }}>Vill du verkligen ta bort den här användaren?</ModalHeader>
                    <ModalFooter style={{ margin: "auto" }}>
                        <Button color="danger" onClick={this.onRemove}>Ja</Button>
                        <Button color="primary" onClick={this.toggleRemoveModal}>Avbryt</Button>
                    </ModalFooter>
                </Modal>
            </ListGroupItem>

        )
    }
}
