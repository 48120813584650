import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Nav, Dropdown, UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import Urls from '../utils/Urls';
import { Roles, GlobalRoles, AdminRoles } from '../utils/Roles'
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isGlobal: false,
            isAdmin: false
        };

    }

    componentDidMount() {
        //this.setState({
        //    isGlobal: true
        //})
        fetch(Urls.WhoIsUser)
            .then(res => {
                if (res.status == 200) {
                    return res.json();
                }
            }).then(user => {
                if (GlobalRoles.includes(user.role)) {
                    this.setState({
                        isGlobal: true,
                        isAdmin: false
                    });
                }
                else if (AdminRoles.includes(user.role)) {
                    this.setState({
                        isAdmin: true,
                        isGlobal: false
                    })
                }
                else {
                    this.setState({
                        isAdmin: false,
                        isGlobal: false
                    })
                }
            })
        .catch(error => console.error(error))
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-lg navbar-light navbar-toggleable-sm bg-white border-bottom box-shadow mb-3">
                    <Container>
                        <NavbarBrand tag={Link} to="/"><img style={{maxHeight: "110px"}} src="/checkin.png" alt="Fastighets&auml;garna" /></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-lg-inline-flex flex-lg-row-reverse text-center" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Hem</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/errands">&Auml;renden</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/history">Historik</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/documentation">Dokument</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/user-settings">Anv&auml;ndarinst&auml;llningar</NavLink>
                                </NavItem>
                                {
                                    (this.state.isAdmin || this.state.isGlobal) &&
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle className="text-dark" nav caret>
                                            Administration
                                    </DropdownToggle>
                                        {
                                            this.state.isGlobal &&
                                            <DropdownMenu right>
                                                <DropdownItem>
                                                    <NavLink tag={Link} className="text-dark" to="/admin-scheme">Schema</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} className="text-dark" to="/admin-users">Anv&auml;ndare och Fastighets&auml;gare</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} className="text-dark" to="/pdf">PDF-hantering</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} className="text-dark" to="/export">&Auml;renden export</NavLink>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        }
                                        {
                                            this.state.isAdmin &&
                                            <DropdownMenu right>
                                                <DropdownItem>
                                                    <NavLink tag={Link} className="text-dark" to="/admin-users">Anv&auml;ndare</NavLink>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        }
                                        
                                    </UncontrolledDropdown>
                                }
                                
                                <LoginMenu>
                                </LoginMenu>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
