import { title } from 'process';
import * as React from 'react'
import Urls from '../utils/Urls';
import { ListGroup, ListGroupItem, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, ButtonToggle, Form, FormGroup, Label, Input, FormFeedback, FormText, Col, Row, Icon, InputGroup, Media } from 'reactstrap';
import App from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faImage, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import ImageUploader from 'react-images-upload';
import { Updating, Success, Error, ErrorMessage } from './visuals/Visuals';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

enum Checked {
    None = 0,
    Fail = -1,
    Approve = 1
}

enum UpdateFlag {
    Idle,
    Updating,
    Success,
    Error
}

type Dictionary = { [index: string]: any }
interface Props {
    subCategory: Dictionary
    listIndex: number
    categoryIndex: number
    currentStatus: Dictionary
    changeProp: Function
    officeName: string
    errandDone: boolean
};
interface State {
    ApprovedStatus: boolean
    FailStatus: boolean
    //ImagePath: string
    Description: string
    ImageIds: Array<string>
    StatusColor: string
    collapseOpen: boolean
    infoModal: boolean
    updating: UpdateFlag
    imageModal: boolean
    activeImage: string
    onImageRemoveModal: boolean
};

export default class ErrandSubCategory extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props)

        this.state = {
            ApprovedStatus: false,
            FailStatus: false,
            //ImagePath: "",
            Description: "",
            ImageIds: [],
            StatusColor: "",
            collapseOpen: false,
            infoModal: false,
            updating: UpdateFlag.Idle,
            imageModal: false,
            activeImage: "",
            onImageRemoveModal: false
        }


        this.statusUpdate = this.statusUpdate.bind(this);
        this.onApproveCheckClicked = this.onApproveCheckClicked.bind(this);
        this.onDescriptionChanged = this.onDescriptionChanged.bind(this);
        this.onImageSelected = this.onImageSelected.bind(this);
        this.onFailCheckClicked = this.onFailCheckClicked.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleInfoModal = this.toggleInfoModal.bind(this);
    }

    componentDidMount() {
        this.statusUpdate(true);
        
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.categoryIndex != this.props.categoryIndex) {
            this.statusUpdate(true);
        }
        
    }

    private async statusUpdate(init : boolean = false) {
        let statusData: Dictionary = this.props.currentStatus;
        let ApprovedStatus = this.state.ApprovedStatus;
        let FailStatus = this.state.FailStatus;
        let ImageIds = [...this.state.ImageIds];
        let StatusColor = this.state.StatusColor;
        if (statusData.Check == Checked.Approve) {
            ApprovedStatus = true;
            FailStatus = false;
            StatusColor = "success";
        }
        else if (statusData.Check == Checked.Fail) {
            FailStatus = true;
            ApprovedStatus = false;
            StatusColor = "danger";
        }
        else {
            FailStatus = false;
            ApprovedStatus = false;
            StatusColor = "";
        }
        this.setState({
            ApprovedStatus,
            FailStatus,
            Description : statusData.Description,
            ImageIds: statusData.ImageIds || [],
            StatusColor,
            updating: init ? UpdateFlag.Idle : UpdateFlag.Success
        })
    }

    private onApproveCheckClicked() {
        this.setState({
            updating: UpdateFlag.Updating
        })
        if (this.state.ApprovedStatus) {
            this.props.changeProp(this.props.subCategory.name, "Check", Checked.None, this.statusUpdate);
        }
        else {
            this.props.changeProp(this.props.subCategory.name, "Check", Checked.Approve, this.statusUpdate);
        }
    }

    private onFailCheckClicked() {
        this.setState({
            updating: UpdateFlag.Updating
        })
        if (this.state.FailStatus) {
            this.props.changeProp(this.props.subCategory.name, "Check", Checked.None, this.statusUpdate);
        }
        else {
            this.props.changeProp(this.props.subCategory.name, "Check", Checked.Fail, this.statusUpdate);
            this.setState({
                collapseOpen: true
            })
        }
    }

    private timeoutId : any;
    private onDescriptionChanged(e: any) {
        let newDesc = e.target.value;
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(function (component: ErrandSubCategory) {
            component.setState({
                updating: UpdateFlag.Updating
            })
            component.props.changeProp(component.props.subCategory.name, "Description", newDesc, component.statusUpdate);
        }, 500, this);
    }

    private onImageSelected(e: any) {
        this.setState({
            updating: UpdateFlag.Updating
        })
        let file = e.target.files[0];
        let allowedExtensions : RegExp =
            /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if (file && allowedExtensions.exec(file.name)) {
            let formData = new FormData();
            formData.append("imageFile", file, file.name);
            formData.append("officeName", this.props.officeName);

            fetch(Urls.UploadImage, {
                method: "POST",
                body: formData
            })
                .then(res => {
                    if (res.status == 200) {
                        return res.json();
                    }
                })
                .then(imageData => {
                    let imageIds: Array<string> = [...this.state.ImageIds, imageData.id];
                    this.props.changeProp(this.props.subCategory.name, "ImageIds", imageIds , this.statusUpdate);
                })
                .catch(e => {
                    console.log(`Problem uploading image: ${e}`);
                })
        }
        else {
            return false;
        }
    }

    private toggleCollapse() {
        this.setState({
            collapseOpen : !this.state.collapseOpen
        })
    }

    private toggleInfoModal() {
        this.setState({
            infoModal: !this.state.infoModal
        })
    }

    private onUploadClick = () => {
        let uploader = document.getElementById(this.props.categoryIndex.toString() + "upload-image-button" + this.props.listIndex.toString()) as HTMLInputElement;
        uploader.click();
    }

    private getUpdateIcon(updateFlag: UpdateFlag) {
        switch (updateFlag) {
            case UpdateFlag.Idle: {
                return null;
            }
            case UpdateFlag.Error: {
                return <Error errorMessage={"Fel vid sparning"} />;
            }
            case UpdateFlag.Success: {
                return <Success />;
            }
            case UpdateFlag.Updating: {
                return <Updating />;
            }
        }
    }

    private onImageClicked = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        let image = event.target as HTMLImageElement;
        this.setState({
            imageModal: !this.state.imageModal,
            activeImage: image.src
        })
    }

    private onImageRemoveModal = () => {
        this.setState({
            onImageRemoveModal: !this.state.onImageRemoveModal
        })
    }

    private onRemoveImage = () => {
        this.setState({
            updating: UpdateFlag.Updating
        })
        let splittedUrl = this.state.activeImage.split('/');
        let imageGuid = splittedUrl[splittedUrl.length - 1];
        let images = [...this.state.ImageIds];
        let index = images.indexOf(imageGuid);
        images.splice(index,1);
        this.props.changeProp(this.props.subCategory.name, "ImageIds", images, this.statusUpdate);
        this.setState({
            onImageRemoveModal: false,
            imageModal: false
        });
    }

    render() {
        var x = window.matchMedia("(max-width: 500px)")
        let nameColumns = 7;
        if (x.matches) {
            nameColumns = 6;
        }
        return (
            <ListGroupItem key={this.props.categoryIndex + "_" + this.props.listIndex} color={this.state.StatusColor} style={{ padding: 0 }}>
                <Row key={this.props.listIndex} style={{margin:"0px", alignItems: "center"}}>
                    <Col xs={nameColumns} style={{padding:"0px"}}>
                        <Button color="primary" onClick={this.toggleCollapse} outline className="btn-no-border btn-no-highlight width-100" style={{textAlign: "left"}}>
                            {this.props.subCategory['name']}
                            <FontAwesomeIcon style={{ float: "right" }} icon={faCaretDown} />
                        </Button>
                    </Col>
                    <Col xs={1}>
                        {
                            this.state.ImageIds.length >= 1 &&
                                <FontAwesomeIcon icon={faImage} />
                        }
                    </Col>
                    <Col xs={1}>
                        <input disabled={this.props.errandDone} type="checkbox" id="checkApproved" checked={this.state.ApprovedStatus} onChange={this.onApproveCheckClicked} />
                    </Col>
                    <Col xs={1}>
                        <input disabled={this.props.errandDone} type="checkbox" id="checkFailed" checked={this.state.FailStatus} onChange={this.onFailCheckClicked} />
                    </Col>
                    <Col xs={1}>
                        <Button outline onClick={this.toggleInfoModal} className="btn-no-border" style={{ padding: ".375rem 0.35rem"}}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </Button>
                    </Col>
                    <Col xs={1}>
                        {this.getUpdateIcon(this.state.updating)}
                    </Col>
                    
                </Row>
                    <Collapse isOpen={this.state.collapseOpen}>
                    <div style={{ margin: "auto", maxWidth: "80%" }}>
                        {
                            !this.props.errandDone &&
                            <FormGroup>
                                <label htmlFor={this.props.categoryIndex.toString() + "upload-image-button" + this.props.listIndex.toString()}>
                                    <Button color="primary" onClick={this.onUploadClick} style={{ marginTop: "7px" }}>
                                        <FontAwesomeIcon icon={faImage} />
                                        <span style={{ marginLeft: "7px" }}>
                                            Lägg till bild
                                        </span>
                                    </Button>
                                </label>
                                <input
                                    type="file"
                                    id={this.props.categoryIndex.toString() + "upload-image-button" + this.props.listIndex.toString()}
                                    style={{ display: "none" }}
                                    onChange={this.onImageSelected}
                                    accept="image/*"
                                />
                            </FormGroup>
                        }
                        {
                            this.state.ImageIds.length > 0 &&
                            <FormGroup className="row">
                                {
                                    this.state.ImageIds.map(id => <Col md={3}><img style={{ marginBottom: "7px", cursor: "pointer" }} className="width-100" onClick={this.onImageClicked} src={`${Urls.GetImage}/${id}`} /></Col>)
                                }
                            </FormGroup>

                        }
                            <FormGroup>
                            <Label>Åtgärd</Label>

                            {/*<ReactQuill value={this.state.Description != undefined ? this.state.Description : ""} readOnly={this.props.errandDone} theme="snow" style={{backgroundColor: "White", color: "#212529"}}*/}
                            {/*    onChange={this.onDescriptionChanged} />*/}
                            <Input type="textarea" name="text" rows={4} onChange={this.onDescriptionChanged} defaultValue={this.state.Description} disabled={this.props.errandDone}/>
                            </FormGroup>
                        </div>
                    </Collapse>
                <Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal}>
                    <ModalHeader toggle={this.toggleInfoModal}>{this.props.subCategory.name}</ModalHeader>
                    <ModalBody dangerouslySetInnerHTML={{ __html: this.props.subCategory.description.replace(/[\n\r]/g, "<br>")}}>
                        
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.imageModal} toggle={this.onImageClicked}>
                    <ModalBody>
                        <FormGroup>
                            <img className="width-100" src={this.state.activeImage} />
                        </FormGroup>
                        <Row key={this.props.listIndex*2}>
                            <Col md={3}>
                                {
                                    !this.props.errandDone &&
                                    <Button color="danger" onClick={this.onImageRemoveModal}>
                                        Ta bort
                                    </Button>
                                }
                            </Col>
                            <Col md={3}>
                            </Col>
                            <Col md={3}>
                            </Col>
                            <Col md={3}>
                                <Button onClick={this.onImageClicked}>
                                    Stäng
                                </Button>
                            </Col>
                        </Row>      
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.onImageRemoveModal}>
                    <ModalHeader style={{ margin: "auto" }}>Vill du ta bort bilden?</ModalHeader>
                    <ModalFooter style={{ margin: "auto" }}>
                        <Button color="danger" onClick={this.onRemoveImage}>Ja</Button>
                        <Button color="primary" onClick={this.onImageRemoveModal}>Avbryt</Button>
                    </ModalFooter>
                </Modal>
            </ListGroupItem>

        )
    }
}
