import * as React from 'react'
import Urls from '../utils/Urls';
import { GlobalRoles } from '../utils/Roles';
import ErrandListItem from '../components/ErrandListItem';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, FormGroup, Label, Input, Col, Row, InputGroup, Spinner, Collapse } from 'reactstrap';
import PaginationComponent from "react-reactstrap-pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
var DatePicker = require("reactstrap-date-picker");

type Dictionary = { [index: string]: any }
interface Props {
    showHistory: boolean
};
interface State {
    isElevated: boolean
    error: string | null
    officeOptions: Array<string>
    selectedOffice: string
    modal: boolean
    errands: Array<Dictionary>
    searchFilteredErrands: Array<Dictionary>
    isLoading: boolean
    selectedPage: number
    startDate: Date
    endDate: Date
    filterCollapse: boolean
};

export default class ErrandsPage extends React.Component<Props, State>
{
    private pageSize = 10;
    constructor(props: Props) {
        super(props)

        this.state = {
            error: null,
            isElevated: false,
            officeOptions: [],
            modal: false,
            errands: [],
            searchFilteredErrands: [],
            isLoading: true,
            selectedOffice: "",
            selectedPage: 1,
            startDate: new Date("01-01-2020"),
            endDate: new Date(),
            filterCollapse: false
        }
        
        this.initWithElevatedRole = this.initWithElevatedRole.bind(this);
        this.initWithoutElevatedRole = this.initWithoutElevatedRole.bind(this);
        this.pageSelected = this.pageSelected.bind(this);

        //this.initWithElevatedRole();

        fetch(Urls.WhoIsUser)
            .then(res => {
                if (res.status == 200) {
                    return res.json();
                }
            })
            .then(user => {
                if (GlobalRoles.includes(user.role)) {
                    this.initWithElevatedRole();
                }
                else {
                    this.initWithoutElevatedRole(user.office);
                }
            })
            .catch(e => {
                console.log(`Problem fetching user: ${e}`);
            })

    }

    private async initWithElevatedRole() {
        let offices = await fetch(Urls.GetOffices + "?$select=name")
            .then(res => {
                if (res.status == 200) {
                    return res.json();
                }
            })
            .then(offices => {
                return offices;
            })
            .catch(e => {
                console.log(`Problem fetching offices: ${e}`);
            })

        let errands = await this.getErrands(offices[0]['Name']);
        this.setState({
            isElevated: true,
            errands: errands,
            searchFilteredErrands: errands,
            officeOptions: offices.map((ofc: Dictionary) => ofc['Name']),
            selectedOffice: offices[0]['Name'],
            isLoading: false
        })
    }

    private async initWithoutElevatedRole(officeName : string) {
        let errands = await this.getErrands();
        this.setState({
            errands: errands,
            searchFilteredErrands: errands,
            isLoading: false,
            selectedOffice: officeName
        });
    }

    private getErrands(officeName: string = "") {
        let url = Urls.GetErrands + "?$filter=status ne 'Klar'";
        if (officeName.length != 0) {
            url = Urls.GetErrandsWithOffice +"?$filter=status ne 'Klar'&officeName="+officeName;
        }
        if (this.props.showHistory) {
            url = url.replace(" ne ", " eq ");
        }
        return fetch(url)
            .then(res => {
                if (res.status == 200) {
                    return res.json();
                }
            })
            .catch(e => {
                console.log(`Problem fetching errands: ${e}`);
            })
    }

    private onSelectOption(event: React.ChangeEvent<HTMLSelectElement>) {
        let selectedOffice = event.target.value;
        this.getErrands(selectedOffice)
            .then(errands => {
                this.setState({
                    errands: errands,
                    searchFilteredErrands: errands,
                    selectedOffice: selectedOffice
                });
            })
    }

    private pageSelected(selectedPage: number) {
        this.setState({
            selectedPage: selectedPage
        });
    }

    private filterOnSearchParams = (searchString: string = "", startDate: string, endDate: string) => {
        let searchResults: Array<Dictionary> = [...this.state.errands];
        searchResults = searchResults.filter(errand => {
            return (
                (
                errand.propertyDesignaton.toLowerCase().includes(searchString.toLowerCase())
                || errand.name.toLowerCase().includes(searchString.toLowerCase())
                )
                && new Date(errand.created) >= new Date(startDate)
                && new Date(errand.created) <= new Date(endDate)
            )
        })
        this.setState({
            searchFilteredErrands: searchResults,
            startDate: new Date(startDate),
            endDate: new Date(endDate)
        })
    }

    private searchStringOnChange = (e: any) => {
        let searchString = e.target.value || "";
        let startDate = document.getElementById("startDate") as HTMLInputElement;
        let endDate = document.getElementById("endDate") as HTMLInputElement;
        this.filterOnSearchParams(searchString, startDate.value, endDate.value);
    }

    private startDateOnChange = (iso: string, formatted: string) => {
        let search = document.getElementById("searchInput") as HTMLInputElement;
        let endDate = document.getElementById("endDate") as HTMLInputElement;
        this.filterOnSearchParams(search.value, iso, endDate.value);
    }

    private endDateOnChange = (iso : string, formatted: string) => {
        let search = document.getElementById("searchInput") as HTMLInputElement;
        let startDate = document.getElementById("startDate") as HTMLInputElement;
        this.filterOnSearchParams(search.value, startDate.value, iso);
    }

    private toggleCollapse = () => {
        this.setState({
            filterCollapse: !this.state.filterCollapse
        })
    }

    

    render() {
        let currentErrands = [];
        let start = this.pageSize * (this.state.selectedPage-1);
        let end = start + this.pageSize >= this.state.searchFilteredErrands.length ? this.state.searchFilteredErrands.length : start+this.pageSize;
        for (var i = start; i < end; i++) {
            currentErrands.push(this.state.searchFilteredErrands[i]);
        }
        return (
            <div>
                {
                    this.state.isLoading &&
                    <Spinner color="primary" />
                }
                {
                    !this.state.isLoading &&
                    <div style={{ maxWidth: "700px", margin: "auto" }}>
                        {
                            this.state.isElevated &&
                            <FormGroup>
                                <h5>Fastighetsägare</h5>
                                <select id="officeSelect" onChange={this.onSelectOption.bind(this)} className="form-control">
                                    {
                                        this.state.officeOptions.map(ofc => <option key={ofc}>{ofc}</option>)
                                    }
                                </select>
                            </FormGroup>
                        }
                        <FormGroup>
                            <Row>
                                <Col md={6}>
                                        <h5>Ärenden</h5>
                                    
                                </Col>
                                <Col md={3}>
                                    {
                                        !this.props.showHistory &&
                                        <Button color="primary" className="width-100" onClick={() => { window.location.href = "/report" }}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            <span style={{ marginLeft: "7px" }}>Nytt ärende</span>
                                        </Button>
                                    }
                                </Col>
                                <Col md={3}>
                                    <Button color="primary" className="width-100" onClick={this.toggleCollapse}>
                                        <FontAwesomeIcon icon={faFilter} />
                                        <span style={{ marginLeft: "7px" }}>Filter</span>
                                    </Button>
                                </Col>
                                
                            </Row>
                        </FormGroup>
                        <Collapse isOpen={this.state.filterCollapse}>
                            <FormGroup>
                                <Row>
                                    <Col md={12}>
                                        <Label>
                                            Sök på ärendets namn och fastighetsbeteckning
                                        </Label>
                                        <Input id="searchInput" onChange={this.searchStringOnChange} placeholder="Sök..." />
                                    </Col>
                                    <Col xs={6}>
                                        <Label>
                                            Från och med
                                        </Label>
                                        <DatePicker id="startDate" dateFormat="DD-MM-YYYY" onChange={this.startDateOnChange} value={this.state.startDate.toISOString()} />
                                    </Col>
                                    <Col xs={6}>
                                        <Label>
                                            Till och med
                                        </Label>
                                        <DatePicker id="endDate" dateFormat="DD-MM-YYYY" onChange={this.endDateOnChange} value={this.state.endDate.toISOString()} />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Collapse>
                        <FormGroup style={{overflowWrap:"anywhere"}}>
                            <ListGroupItem className="btn-no-border" style={{ padding: "0.375rem 0.75rem" }}>
                                <InputGroup className="btn-no-border width-90 flex">
                                    <span className="text-left width-10"><b>Id</b></span>
                                    <span className="text-left width-30"><b>Rubrik</b></span>
                                    <span className="text-left width-30"><b>Fastighetsbeteckning</b></span>
                                    <span className="width-30 text-center"><b>Status</b></span>
                                </InputGroup>
                            </ListGroupItem>
                            <ListGroup>
                                
                                {
                                   currentErrands.map((errand) => <ErrandListItem errand={errand} />)
                                }
                            </ListGroup>
                        </FormGroup>
                        <div className="pager">
                            <PaginationComponent
                                totalItems={this.state.errands.length}
                                pageSize={this.pageSize}
                                onSelect={this.pageSelected}
                                maxPaginationNumbers={3}
                                firstPageText={"Första sidan"}
                                previousPageText={"Föregående sida"}
                                nextPageText={"Nästa sida"}
                                lastPageText={"Sista sidan"}
                                />
                        </div>
                    </div>
                }
            </div >
            )
    }
}