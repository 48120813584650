import { title } from 'process';
import Urls from '../utils/Urls';
import { GlobalRoles, AdminRoles, Roles } from '../utils/Roles';
import * as React from 'react';
import UserListItem from '../components/UserListItem';
import { ListGroup, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, Col, Row, FormFeedback, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';

type Dictionary = { [index: string]: any }
interface Props { role: string };
interface State {
    error: string | null
    dropdownOpen: boolean
    offices: Array<Dictionary>
    users: Array<Dictionary>
    userRoles: Dictionary
    selectedOffice: Dictionary
    modal: boolean
    modalText: string
    changesAlert: boolean
    officeModal: boolean
    addUserModal: boolean
    isGlobalAdmin: boolean
    loading: boolean
    isOfficeEdit: boolean
    officeModalHeader: string
    officeModalButtonText: string
};

export default class AdminUserPage extends React.Component<Props, State>
{

    constructor(props: Props) {
        super(props)

        this.state = {
            error: null,
            isGlobalAdmin: false,
            modal: false,
            modalText: "",
            officeModal: false,
            addUserModal: false,
            changesAlert: false,
            dropdownOpen: false,
            offices: [],
            users: [],
            userRoles: Roles,
            selectedOffice: {},
            loading: true,
            isOfficeEdit: false,
            officeModalHeader: "",
            officeModalButtonText: ""
        }

        //this.InitGlobalAdminData();

        fetch(Urls.WhoIsUser)
            .then(currentUserInfoRes => {
                if (currentUserInfoRes.status == 200) {
                    return currentUserInfoRes.json();
                }
            })
            .then(currentUser => {
                if (GlobalRoles.includes(currentUser.role)) {
                    this.InitGlobalAdminData();
                }
                else if (AdminRoles.includes(currentUser.role)) {
                    this.getUsersByOffice(currentUser.office.name)
                        .then(users => {
                            this.setState({
                                selectedOffice: currentUser.office,
                                users: users,
                                loading: false
                            })
                        })
                }
                else {
                    window.location.href = "/";
                }
            })

        this.addOffice = this.addOffice.bind(this);
        this.addUser = this.addUser.bind(this);
        this.onAddUser = this.onAddUser.bind(this);
        this.onUserChangeOffice = this.onUserChangeOffice.bind(this);
        this.disableUser = this.disableUser.bind(this);
        this.updateUsers = this.updateUsers.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.toggleOfficeModal = this.toggleOfficeModal.bind(this);
        this.toggleAddUserModal = this.toggleAddUserModal.bind(this);
        this.InitGlobalAdminData = this.InitGlobalAdminData.bind(this);
        this.prepareOfficeData = this.prepareOfficeData.bind(this);
        this.editOffice = this.editOffice.bind(this);

    }

    private InitGlobalAdminData() {
        fetch(Urls.GetOffices)
            .then(officesRes => {
                if (officesRes.status == 200) {
                    return officesRes.json();
                }
            })
            .then(offices => {
                this.getUsersByOffice(offices[0].name)
                    .then(users => {
                        this.setState({
                            offices: offices,
                            selectedOffice: offices[0],
                            users: users,
                            isGlobalAdmin: true,
                            loading: false
                        })
                    })
            })
            .catch(e => {
                console.log(`Problem fetching offices: ${e}`);
            })
    }

    private getUsersByOffice(officeName: string) {
        return fetch(`${Urls.GetUsersByOffice}?$select=Email&officeName=${officeName}&$filter=Disabled eq false`)
            .then(usersRes => {
                if (usersRes.status == 200) {
                    return usersRes.json();
                }
            })
            .then(usersInOffice => {
                return usersInOffice;
            })
            .catch(e => {
                console.log(`Problem fetching users in office: ${e}`);
            })
    }

    private showModal(text: string) {
        this.setState({
            modalText: text,
            modal: !this.state.modal
        });
    }

    private toggleModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    private toggleOfficeModal(header: string = "Lägg till ny fastighetsägare", createButtonText: string = "Skapa", edit : boolean = false) {
        this.setState({
            officeModal: !this.state.officeModal,
            isOfficeEdit: edit,
            officeModalButtonText: createButtonText,
            officeModalHeader: header
        });
    }

    private toggleAddUserModal() {
        this.setState({
            addUserModal: !this.state.addUserModal
        });
    }

    private onAddUser() {
        let form: HTMLFormElement = document.getElementsByClassName("needs-validation")[0] as HTMLFormElement;
        let validation = form.checkValidity();
        form.classList.add("was-validated");
        if (validation) {
            this.addUser();
            form.classList.remove("was-validated");
        }
    }

    private addUser() {
        let officeSelect = document.getElementById("newUserOfficeSelect") as HTMLSelectElement;
        let passwordInput = document.getElementById("newUserPasswordInput") as HTMLInputElement;
        let emailInput = document.getElementById("newUserEmailInput") as HTMLInputElement;
        let roleSelect = document.getElementById("newUserRoleSelect") as HTMLSelectElement;
        let role = "";
        Object.keys(Roles).forEach(key => {
            if (roleSelect.value == Roles[key].title)
                role = key;
        })
        let url = "";
        let data = {};
        if (this.state.isGlobalAdmin) {
            data = {
                Role: role,
                UserEmail: emailInput.value,
                Password: passwordInput.value,
                OfficeName: officeSelect.value
            };
            url = Urls.NewUserAdmin;
        }
        else {
            data = {
                Role: role,
                UserEmail: emailInput.value,
                Password: passwordInput.value
            };
            url = Urls.NewUser;
        }
        
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.status == 200) {
                    this.updateUsers();
                    this.showModal("Användaren har lagts till.");
                    this.toggleAddUserModal();
                }
                else if (res.status == 409) {
                    this.showModal("Användaren finns redan");
                }
                else {
                    this.showModal("Något gick fel!");
                }
            })
            .catch(e => {
                console.log(`Problem addning user:  ${e}`);
                this.showModal("Något gick fel!");
            });
    }

    private disableUser(user: Dictionary) {
        let data = {
            Email: user['Email']
        };
        return fetch(Urls.DisableUser, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.status == 200) {
                    this.updateUsers();
                    this.showModal("Användaren har tagits bort");
                }
                else {
                    this.showModal("Något gick fel!");
                }
            })
            .catch(e => {
                console.log(`Problem removing user: ${e}`);
            })
    }

    private updateUsers(officeName: string = this.state.selectedOffice.name) {
        if (officeName.length < 1) {
            officeName = this.state.selectedOffice.name;
        }
        this.getUsersByOffice(officeName)
            .then(users => {
                this.setState({
                    users: users
                });
            })
    }

    private onUserChangeOffice(userEmail: string, officeName: string) {
        fetch(`${Urls.UpdateUserOffice}?newOfficeName=${officeName}&userEmail=${userEmail}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }//,
            //body: JSON.stringify(data)
        })
            .then(res => {
                if (res.status == 200) {
                    this.getUsersByOffice(this.state.selectedOffice.name)
                        .then(users => {
                            this.setState({
                                users: users
                            });
                        })
                }
                else {
                    this.showModal("Något gick fel!");
                }
            })
            .catch(e => {
                console.log(`Problem updating user office: ${e}`);
                this.showModal("Något gick fel!");
            })
    }

    private prepareOfficeData() {
        let inputName = document.getElementById("officeNameInput") as HTMLInputElement;
        let inputContact = document.getElementById("officeContactInput") as HTMLInputElement;
        let inputAddress = document.getElementById("officeAddressInput") as HTMLInputElement;
        let inputPhone = document.getElementById("officePhoneInput") as HTMLInputElement;
        let inputComment = document.getElementById("officeCommentInput") as HTMLInputElement;
        if (inputName.value.length == 0 || this.state.offices.find(ofc => ofc.name == inputName.value)) {
            inputName.classList.add("is-invalid");
            return;
        }
        let officeData : Dictionary = {
            name: inputName.value,
            contactPerson: inputContact.value,
            phone: inputPhone.value,
            address: inputAddress.value,
            comment: inputComment.value
        };
        if (this.state.isOfficeEdit) {
            officeData["id"] = this.state.selectedOffice.id
            this.editOffice(officeData);
        }
        else {
            this.addOffice(officeData);
        }
    }

    private addOffice(officeData : Dictionary) {
        fetch(Urls.AddOffice, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(officeData)
        })
            .then(res => {
                if (res.status == 200) {
                    let newOffices = [...this.state.offices];
                    newOffices.push(officeData);
                    this.setState({
                        offices: newOffices,
                        selectedOffice: officeData.name,
                        users: []
                    }, () => {
                            let officeSelect = document.getElementById("officeSelect") as HTMLSelectElement;
                            officeSelect.value = officeData.name;
                            this.toggleOfficeModal();
                    });
                }
            })
    }

    private editOffice(officeData: Dictionary) {
        fetch(Urls.UpdateOffice, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(officeData)
        })
            .then(res => {
                if (res.status == 200) {
                    let newOffices = [...this.state.offices];
                    let index = newOffices.findIndex(ofc => ofc.name == officeData.name);
                    newOffices.splice(index, 1, officeData);
                    this.setState({
                        offices: newOffices,
                        selectedOffice: officeData.name
                    }, () => {
                        let officeSelect = document.getElementById("officeSelect") as HTMLSelectElement;
                        officeSelect.value = officeData.name;
                        this.toggleOfficeModal();
                    });
                }
            })
    }

    private onSelectOption(event: React.ChangeEvent<HTMLSelectElement>) {
        let selected = event.target.value;
        let selectedOffice: Dictionary = this.state.offices.filter((ofc: Dictionary) => ofc.name == selected)[0];
        if (selectedOffice) {
            this.getUsersByOffice(selectedOffice.name)
                .then(users => {
                    this.setState({
                        users: users,
                        selectedOffice: selectedOffice
                    });
                })
        }
    }

    render() {
        let officeIndex = this.state.offices.findIndex(ofc => ofc.name == this.state.selectedOffice);
        return (
            
            <div>
                {
                    this.state.loading &&
                    <Spinner color="primary" />
                }
                {
                    !this.state.loading &&

                    <Form  style={{ maxWidth: "700px", margin: "auto" }}>
                        {
                            this.state.isGlobalAdmin &&

                            <FormGroup>
                                <h5>Fastighetsägare</h5>
                                <Row>
                                    <Col md={5}>
                                        <select id="officeSelect" onChange={this.onSelectOption.bind(this)} className="form-control">
                                            {
                                                this.state.offices.map(ofc => <option key={ofc.name}>{ofc.name}</option>)
                                            }
                                        </select>
                                    </Col>
                                    <Col md={3}>
                                        <Button size="md" className="width-100" onClick={(() => { this.toggleOfficeModal("Redigera Fastighetsägare", "Spara", true) }).bind(this)} color="primary">
                                            <FontAwesomeIcon icon={faEdit} />
                                            <span style={{ marginLeft: "7px" }}>Redigera</span>
                                        </Button>
                                    </Col>
                                    <Col md={4}>
                                        <Button size="md" className="width-100" onClick={(() => { this.toggleOfficeModal() }).bind(this)} color="primary">
                                            <FontAwesomeIcon icon={faPlus} />
                                            <span style={{ marginLeft: "7px" }}>Ny fastighetsägare</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        }

                        <FormGroup>
                            <h5>Användare</h5>
                            <ListGroup>
                                {
                                    this.state.users.map((user, i) => <UserListItem office={this.state.selectedOffice} isGlobalAdmin={this.state.isGlobalAdmin} officeIndex={officeIndex} listIndex={i} user={user} offices={this.state.offices} onUserChangeOffice={this.onUserChangeOffice} disableUser={this.disableUser} />)
                                }
                            </ListGroup>
                        </FormGroup>
                        <FormGroup>
                            <Button size="md" className="width-100" onClick={this.toggleAddUserModal} color="primary">
                                <FontAwesomeIcon icon={faPlus} />
                                <span style={{ marginLeft: "7px" }}>Ny användare</span>
                            </Button>
                        </FormGroup>
                    </Form>
                }
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} >
                    <ModalHeader style={{ margin: "auto" }}>{this.state.modalText}</ModalHeader>
                    <ModalFooter style={{ margin: "auto" }}>
                        <Button color="primary" onClick={this.toggleModal}>Ok</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.officeModal} toggle={this.toggleOfficeModal} backdrop={"static"} keyboard={false}>
                    <ModalHeader style={{ margin: "auto" }}>{this.state.officeModalHeader}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>Namn</Label>
                            {
                                this.state.isOfficeEdit ?
                                <Input id="officeNameInput" defaultValue={this.state.selectedOffice.name} /> :
                                <Input id="officeNameInput" />
                            }
                            <FormFeedback>Ett namn som inte finns måste anges</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Adress</Label>
                            {
                                this.state.isOfficeEdit ?
                                <Input id="officeAddressInput" defaultValue={this.state.selectedOffice.address}/> :
                                <Input id="officeAddressInput" />
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label>Kontaktperson</Label>
                            {
                                this.state.isOfficeEdit ?
                                    <Input id="officeContactInput" defaultValue={this.state.selectedOffice.contactPerson} /> :
                                    <Input id="officeContactInput" />
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label>Telefon</Label>
                            {
                                this.state.isOfficeEdit ?
                                    <Input id="officePhoneInput" defaultValue={this.state.selectedOffice.phone} /> :
                                    <Input id="officePhoneInput" />
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label>Kommentar</Label>
                            {
                                this.state.isOfficeEdit ?
                                    <Input type="textarea" rows={4} id="officeCommentInput" defaultValue={this.state.selectedOffice.comment} /> :
                                    <Input type="textarea" rows={4} id="officeCommentInput" />
                            }
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col md={3}>
                                    <Button color="primary" className="width-100" onClick={this.prepareOfficeData}>{this.state.officeModalButtonText}</Button>
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <Button color="secondary" className="width-100" onClick={(() => { this.toggleOfficeModal() }).bind(this)}>Avbryt</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.addUserModal} toggle={this.toggleAddUserModal}>
                    <ModalBody>
                        <Form className="needs-validation">
                            <FormGroup>
                                <Label>Epost</Label>
                                <Input required type="email" id="newUserEmailInput" />
                                <FormFeedback>En epost som inte finns måste anges</FormFeedback>
                            </FormGroup>
                            {
                                this.state.isGlobalAdmin &&
                                <FormGroup>
                                    <Label>Fastighetsägare</Label>
                                    <select id="newUserOfficeSelect" defaultValue={this.state.selectedOffice.name} className="form-control" style={{ marginBottom: "0.5rem" }}>
                                        {
                                            this.state.offices.map(ofc => <option>{ofc.name}</option>)
                                        }
                                    </select>
                                </FormGroup>
                            }
                            <FormGroup>
                                <Label>Behörighetsroll</Label>
                                <select id="newUserRoleSelect"  className="form-control" style={{ marginBottom: "0.5rem" }}>
                                    {
                                        this.state.isGlobalAdmin &&
                                        Object.keys(this.state.userRoles).map(key => <option>{Roles[key].title}</option>)
                                    }
                                    {
                                        !this.state.isGlobalAdmin &&
                                        Object.keys(this.state.userRoles).map(key => {
                                            if(!GlobalRoles.includes(key)){
                                                return <option>{Roles[key].title}</option>;
                                            }  
                                        })
                                    }
                                </select>
                            </FormGroup>
                            <FormGroup>
                                <Label>Ange ett lösenord</Label>
                                <Input required style={{ marginBottom: "0.5rem" }} type="password" id="newUserPasswordInput" />
                                <div style={{ marginLeft: 4, fontSize: "12px" }}>
                                    <div>- Minst 6 tecken</div>
                                    <div>- Både VERSALER och gemener</div>
                                    <div>- Minst 1 siffra</div>
                                </div>
                            </FormGroup>
                        </Form>
                        <FormGroup>
                            <Row>
                                <Col md={3}>
                                    <Button color="primary" className="width-100" onClick={this.onAddUser}>Skapa</Button>
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                </Col>
                                <Col md={3}>
                                    <Button color="secondary" className="width-100" onClick={this.toggleAddUserModal}>Avbryt</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </ModalBody>
                </Modal>
                
            </div >
        )
    }
}