const prefix = "/api";
//const prefix = "https://localhost:44301"

const Urls = {
    GetUsersByOffice: prefix+"/AspNetUsers/GetOfficeUsers", //officeName : string
    UpdateUserOffice: prefix+"/AspNetUsers/UpdateUserOffice", //newOfficeName : string, userEmail : string
    UpdateUserPassword: prefix + "/AspNetUsers/UpdateUserPassword",
    UpdateMyPassword: prefix + "/AspNetUsers/UpdateMyPassword",
    NewUser: prefix+"/AspNetUsers/NewUser",
    NewUserAdmin: prefix+"/AspNetUsers/NewUserAdmin",
    DisableUser: prefix+"/AspNetUsers/DisableUser",
    CreateErrand: prefix+"/Errands/Create",
    CreateErrandWithOffice: prefix + "/Errands/CreateWithOffice", //officeName : string
    GetErrands: prefix + "/Errands/GetErrands",
    GetErrandsWithOffice: prefix + "/Errands/GetErrandsByOffice", //officeName : string
    GetErrandById: prefix + "/Errands/GetErrand",
    GetErrandByIdEl: prefix + "/Errands/ElevatedGetErrand",
    UpdateErrand: prefix + "/Errands/Update",
    FinishErrand: prefix + "/Errands/ToggleDone",
    GetOffices: prefix+"/Offices/GetOffices",
    //GetUserOffice: prefix+"/Offices/UserOffice",
    AddOffice: prefix + "/Offices/AddOffice",
    UpdateOffice: prefix + "/Offices/UpdateOffice",
    GetLatestScheme: prefix+"/Schemes/GetLatest",
    UpdateScheme: prefix + "/Schemes/Update",
    WhoIsUser: prefix + "/AspNetUsers/WhoAmI",
    //GetImage: prefix + "/Images",
    GetImage: prefix + "/Images/GetImage",
    UploadImage: prefix + "/Images/Upload",
    UploadPDF: prefix + "/Pdf/Upload",
    GetPDF: prefix + "/Pdf/GetPdf",
    GetFiles: prefix + "/Pdf/GetFiles",
    DeleteFile: prefix + "/Pdf/DeleteFile",
    GetErrandsWithinInterval: prefix + "/Errands/GetErrandsWithinInterval"
}

export default Urls;