import * as React from 'react'
import Urls from '../utils/Urls'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Spinner, Row, Col, Input, Label, Alert } from 'reactstrap'
import { faCross, faExternalLinkAlt, faFilePdf, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Updating, Success, Error, ErrorMessage } from '../components/visuals/Visuals';

type Dictionary = { [index: string]: any }

enum UpdateFlag {
    Idle,
    Updating,
    Success,
    Error
}

interface Props { };
interface State {
    error: string | null
    modal: boolean
    pdfs: string[];
    //PdfUrl: string
    loading: boolean
    updating: UpdateFlag;
    newPdfModal: boolean;
    deletePdfModal: boolean;
    newPdfError: string;
};

export default class ChangePdfPage extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props)

        this.state = {
            error: null,
            modal: false,
            pdfs: [],
            //PdfUrl: Urls.GetPDF,
            loading: true,
            updating: UpdateFlag.Idle,
            newPdfModal: false,
            deletePdfModal: false,
            newPdfError: ""
        }

       
    }


    componentDidMount() {
        fetch(Urls.GetFiles)
            .then(res => {
                return res.json();
            })
            .then(json => {
                this.setState({pdfs : json, loading: false})
            })
    }
    private activeFileUpload = "";
    private onUploadClick = (id : string) => {
        let uploader = document.getElementById(id) as HTMLInputElement;
        this.activeFileUpload = id;
        uploader.click();
    }

    private getUpdateIcon(updateFlag: UpdateFlag) {
        switch (updateFlag) {
            case UpdateFlag.Idle: {
                return null;
            }
            case UpdateFlag.Error: {
                return <Error errorMessage={"Fel vid sparning"} />;
            }
            case UpdateFlag.Success: {
                return <Success />;
            }
            case UpdateFlag.Updating: {
                return <Updating />;
            }
        }
    }

    private onPdfSelected = (e: any) => {
        console.log(e.target.id)
        this.setState({
            //loading: true,
            updating: UpdateFlag.Updating
        })
        let file = e.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append("pdfFile", file, file.name);

            fetch(Urls.UploadPDF+"/"+this.activeFileUpload, {
                method: "POST",
                body: formData
            })
                .then(res => {
                    if (res.status == 200) {
                        this.setState({
                            //PdfUrl: Urls.GetPDF,
                            //loading: false,
                            updating: UpdateFlag.Success
                        })
                    }
                })
                .catch(e => {
                    
                    console.log(`Problem uploading PDF: ${e}`);
                    this.setState({
                        //loading: false,
                        updating: UpdateFlag.Error
                    })
                })
        }
    }

    private toggleNewPdfModal = () => {
        this.setState({
            newPdfModal: !this.state.newPdfModal
        });
    }

    private openDeletePdfModal = (file:string) => {
        this.activeFileUpload = file;
        this.setState({
            deletePdfModal: true
        });
    }

    private onNewPdf = () => {
        let inputEl = document.getElementById("newPdfTitle") as HTMLInputElement;
        if (this.state.pdfs.indexOf(inputEl.value) >= 0) {
            this.setState({
                newPdfError: "En PDF men det namnet finns redan"
            });
            return;
        }
        if (inputEl.value.trim().length == 0) {
            this.setState({
                newPdfError: "Det måste finnas ett PDF-namn"
            });
            return;
        }

        this.setState({
            pdfs: [...this.state.pdfs, inputEl.value],
            newPdfModal: false,
            newPdfError: ""
        });
    }

    private removePdf = () => {
        fetch(Urls.DeleteFile + "/" + this.activeFileUpload)
            .then(res => {
                if (res.status == 200) {
                    let pdfs = [...this.state.pdfs];
                    let index = pdfs.indexOf(this.activeFileUpload);
                    pdfs.splice(index);
                    this.setState({
                        deletePdfModal: false,
                        pdfs: pdfs
                    })
                }
            })
    }

    render() {
        return (
            <div style={{ maxWidth: "700px", margin: "auto" }}>
                {
                    //this.state.loading &&
                    //<Spinner color="primary" />
                }
                {
                    !this.state.loading &&
                    <div>
                        <h5>PDF-filer</h5>
                        <FormGroup>
                            {
                                this.state.pdfs.map(file =>
                                    <>
                                    <h5>{file}</h5>
                                        <Row key={file} style={{borderBottom: "1px solid"}}>
                                        <Col md={3}>
                                                <label htmlFor={file} className="width-100">
                                                    <Button color="primary" className="width-100" onClick={() => { this.onUploadClick(file) }} style={{ marginTop: "7px" }}>
                                                    <FontAwesomeIcon icon={faFilePdf} />
                                                    <span style={{ marginLeft: "7px" }}>
                                                        Byt PDF
                                        </span>
                                                </Button>
                                            </label>
                                            <input
                                                type="file"
                                                    id={file}
                                                style={{ display: "none" }}
                                                onChange={this.onPdfSelected}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <a href={Urls.GetPDF+"/"+file} target="_blank">
                                                <Button color="primary" className="width-100" style={{ marginTop: "7px" }}>
                                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                                    <span style={{ marginLeft: "7px" }}>
                                                        Se nuvarande
                                        </span>
                                                </Button>
                                            </a>
                                            </Col>
                                            <Col md={3}>
                                                <Button onClick={() => { this.openDeletePdfModal(file) }} color="danger" className="width-100" style={{ marginTop: "7px" }}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                        <span style={{ marginLeft: "7px" }}>
                                                            Ta bort
                                        </span>
                                                    </Button>
                                            </Col>
                                            {
                                                this.activeFileUpload == file &&
                                                <Col md={1} style={{ alignSelf: "center" }}>
                                                    {this.getUpdateIcon(this.state.updating)}
                                                </Col>
                                            }
                                        
                                        </Row>
                                        </>)
                            }
                            
                        </FormGroup>
                        <FormGroup>
                            <Button onClick={this.toggleNewPdfModal} color="primary" className="width-100" style={{ marginTop: "7px" }}>
                                <FontAwesomeIcon icon={faPlus} />
                                <span style={{ marginLeft: "7px" }}>
                                    Lägg till ny
                                        </span>
                            </Button>
                        </FormGroup>
                        {
                            //<FormGroup>
                            //    <embed width="100%" height="1000px" src={this.state.PdfUrl} />
                            //</FormGroup>
                        }
                        <Modal isOpen={this.state.newPdfModal} toggle={this.toggleNewPdfModal}>
                            <ModalHeader toggle={this.toggleNewPdfModal}>Ny PDF</ModalHeader>
                            <ModalBody>
                                <Label>PDF-namn</Label>
                                <Input type="text" id="newPdfTitle" defaultValue="" />
                                    <Alert color="danger" style={{ marginTop: "7px" }} isOpen={this.state.newPdfError != ""} >
                                        {this.state.newPdfError}
                                </Alert>
                                
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary"  onClick={this.onNewPdf}>Lägg till</Button>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={this.state.deletePdfModal} toggle={this.openDeletePdfModal}>
                            <ModalHeader toggle={() => { this.activeFileUpload = ""; this.setState({deletePdfModal: false}) }}>Vill du ta bort PDF?</ModalHeader>
                            <ModalFooter>
                                <Button color="danger" onClick={this.removePdf}>Ja</Button>
                                <Button color="primary" onClick={() => { this.activeFileUpload = ""; this.setState({ deletePdfModal: false }) }}>Avbryt</Button>
                            </ModalFooter>
                        </Modal>
                        
                        </div>
                }
                
            </div>
        )
    }
}
